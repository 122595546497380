import { Directive, ElementRef, HostListener, Input } from '@angular/core';


@Directive({
  selector: '[appCharacterAllowed]'
})
export class CharacterAllowedDirective {

  @Input('appCharacterAllowed') allowedCharacters: string = '';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let inputValue: string = this.el.nativeElement.value;
    
    const regex = new RegExp(this.allowedCharacters);

    if (!regex.test(inputValue)) {
      event.preventDefault();
      this.el.nativeElement.value = inputValue.replace(new RegExp(this.allowedCharacters, 'g'), '');
    }
  }
}