import { ElementRef } from "@angular/core";

export class Estilos {

  cambioEstilos(elementRef : ElementRef){
    let ambiente = sessionStorage.getItem('ambiente');
    if(ambiente != ''){
      if(ambiente == 'EMBEADQUIR' || ambiente == 'EMBECOMER'){
        
        let data = JSON.parse(sessionStorage.getItem('userPerfil'));
        if(data.estilos.botonNormal != ''){
          elementRef.nativeElement.style.setProperty('--colorbtn', data.estilos.botonNormal);
        }
        if(data.estilos.botonHover != ''){
            elementRef.nativeElement.style.setProperty('--colotbtnHover', data.estilos.botonHover);
            elementRef.nativeElement.style.setProperty('--colorbtnHover2', data.estilos.botonHover);
        }
      }
    }
  }
}