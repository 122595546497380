import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ValidaRecuperarPassCambioClaveService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('recuperarPassValidaSmsV')) {
      return true;
    } else {
      /* // Si no esta activa la sesion, redirecciona al login
      console.log("Security");*/
      this.router.navigate(['inicio']);
      return false;
    }

  }

}