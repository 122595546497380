import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CondGeneralFinalPeticion } from 'src/app/models/condiciones-generales/condGeneralFinalPeticion';
import { CondGeneralPeticion } from 'src/app/models/condiciones-generales/condGeneralPeticion';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*'
    })
};

@Injectable()
export class CondicionesGeneralesService {
    private urlCondGenerales = "/apiMandatos/condicionesGenerales";
    private urlCondGeneralesPDF = "/apiMandatos/condicionesGenerales/pdf";

    private urlCondGeneralesFinal = "/apiMandatos/condicionesGeneralesFinal";
    private urlCondGeneralesFinalPDF = "/apiMandatos/condicionesGeneralesFinal/pdf"

    private urlCondGeneralesExpress = "/apiMandatos/condicionesGeneralesExpress";
    private urlCondGeneralesFinalExpress = "/apiMandatos/condicionesGeneralesFinalExpress";
    private urlCondGeneralesFinalExpressPDF = "/apiMandatos/condicionesGeneralesFinalExpress/pdf"

    constructor(private http: HttpClient) { }


    doCondicionesGenerales(_body: CondGeneralPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGenerales, _body, httpOptions);
    }

    doCondicionesGeneralesPDF(_body: CondGeneralPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesPDF, _body, httpOptions);
    }

    doCondicionesGeneralesFinal(_body: CondGeneralFinalPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesFinal, _body, httpOptions);
    }

    doCondicionesGeneralesFinalPDF(_body: CondGeneralFinalPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesFinalPDF, _body, httpOptions);
    }

    doCondicionesGeneralesExpress(_body: CondGeneralPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesExpress, _body, httpOptions);
    }

    doCondicionesGeneralesFinalExpress(_body: CondGeneralFinalPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesFinalExpress, _body, httpOptions);
    }

    doCondicionesGeneralesFinalExpressPDF(_body: CondGeneralFinalPeticion): Observable<any> {
        return this.http.post<any>(this.urlCondGeneralesFinalExpressPDF, _body, httpOptions);
    }

}