import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

@Injectable()
export class ValidaMenuService implements CanActivateChild {
  userPerfil;
  lstModulos;
  constructor(private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.lstModulos = this.userPerfil.perfil.modulos;

    for (let index = 0; index < this.lstModulos.length; index++) {
      const modulo = this.lstModulos[index];
      if (this.listaMenus(modulo, route.url)) {
        return true;
      }
    }
    console.log("validaMenu");
    this.router.navigate(['prelogin']);
    return false;
  }

  listaMenus(element: any, url: UrlSegment[]) {

    if (url == element.url) {
      return true;
    } else {
      const menus = element.menus;
      if (menus != null && menus.length > 0) {
        for (let index = 0; index < menus.length; index++) {
          if (this.listaMenus(menus[index], url)) {
            return true;
          }
        }
      } else {
        return false;
      }
    }
  }
}