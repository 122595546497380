import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class PoliticaPrivacidadService {
  constructor(private http: HttpClient, @Inject('env') private environment: any) { }

  urlUploadPoliticaPrivacidad = this.environment.urlAmbiente + '/apiDocumentos/uploadDocumento';
  urlMostrarPoliticaPrivacidad = this.environment.urlAmbiente + '/apiDocumentos/downloadDocumento';

  postUploadPoliticaPrivacidad(archivoPdf: FormData): Observable<any> {
    return this.http.post<any>(this.urlUploadPoliticaPrivacidad, archivoPdf, httpOptions);
  }

  postMostrarPoliticasPrivacidad(): Observable<any> {
    return this.http.post<any>(this.urlMostrarPoliticaPrivacidad, null, httpOptions);
  }
}



