import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: []
})
export class MenuComponent implements OnInit, DoCheck {
  userPerfil: { [x: string]: any; };
  perfil: any;
  urlSelected: string;
  showHome: boolean = true;

  constructor(public router: Router) { }

  ngOnInit() {
    this.showHome = false;
    this.urlSelected = sessionStorage.getItem('urlSelected');
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.perfil = this.userPerfil['perfil'];

  }

  ngDoCheck() {
    this.onClickMenu(this.router.url);
  }

  onClickMenu(url: any) {
    if (url != '/home/habiente') {
      this.showHome = false;
    } else {
      this.showHome = true;
    }
  }
}