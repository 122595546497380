import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrearCuentaCorreoPeticion } from 'src/app/models/crear-cuenta/crearCuentaCorreo-peticion';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';
import { environment } from 'src/environments/environment';
import { DialogBaseComponent } from '../../dialogs/dialog-base/dialog-base.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-crear-cuenta-correo',
  templateUrl: './crear-cuenta-correo.component.html',
  styleUrls: ['./crear-cuenta-correo.component.css'],
  providers: [CrearCuentaService]
})
export class CrearCuentaCorreoComponent implements OnInit {
  FormGroup: FormGroup;
  loading: boolean = false;
  errorValidaCorreo: boolean;
  errorMensage: string;
  crearCuentaCorreoPeticion = new CrearCuentaCorreoPeticion();
  preLoginsLS: { codTpoPortal: string; };
  estadoboton:boolean = false;

  constructor(private router: Router, private crearCuentaService: CrearCuentaService, public dialog: MatDialog) {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
  }

  ngOnInit() {
    sessionStorage.removeItem('reenviar');
    sessionStorage.removeItem('countDown');

    this.FormGroup = new FormGroup({
      correo: new FormControl('', Validators.compose([Validators.email, Validators.maxLength(50), Validators.required])),
      codmovil: new FormControl('', Validators.compose([Validators.maxLength(2), Validators.pattern('^[0-9 ]*$'), Validators.required])),
      movil: new FormControl('', Validators.compose([Validators.maxLength(9), Validators.minLength(8), Validators.pattern('^[0-9 ]*$'), Validators.required]))
    });
  }

  validaCorreo() {
    this.loading = true;
    this.crearCuentaCorreoPeticion.correoUsuario = this.FormGroup.get('correo').value;
    this.crearCuentaCorreoPeticion.codCliente = environment.codCliente;
    this.crearCuentaCorreoPeticion.docEmpresa = environment.docEmpresa;
    this.crearCuentaCorreoPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.estadoboton=true;
    this.crearCuentaService.doValidaCorreo(this.crearCuentaCorreoPeticion).subscribe(data => {
      //console.log(data);
      this.estadoboton=false;
      if (data.codret == '0' || data.codret == '1') {
        sessionStorage.setItem('crearCuentaCorreo', JSON.stringify(data));
        sessionStorage.setItem('correoValido', this.FormGroup.get('correo').value);
        sessionStorage.setItem('movilValido', this.FormGroup.get('movil').value);
        sessionStorage.setItem('countryMovilUsuario', this.FormGroup.get('codmovil').value);

        this.router.navigate(['/crearcuentaclave']);
      } else {
        this.errorValidaCorreo = true;
        this.errorMensage = 'Código de error: '+ data.descret;
        this.loading = false;
      }
    }, error => {
      this.estadoboton=false;
      console.error("Error envio correo", error)
      this.loading = false;
      this.showDialog('Error', 'Servicio temporalmente no disponible.', false);
    });
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/inicio']);
      }
    });
  }
}
