import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CrearCuentaDatosPeticion } from 'src/app/models/crear-cuenta/crearCuentaDatos-peticion';
import { PoliticaPeticion } from 'src/app/models/politica/politica-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';
import { CustomValidators } from 'src/app/util/custom-validators';
import { environment } from 'src/environments/environment';
import { DialogPoliticaPrivacidadComponent } from '../../dialogs/dialog-politica-privacidad/dialog-politica-privacidad.component';

@Component({
  selector: 'app-crear-cuenta-datos',
  templateUrl: './crear-cuenta-datos.component.html',
  styleUrls: ['./crear-cuenta-datos.component.css'],
  providers: [CrearCuentaService, AutenticadorService]
})
export class CrearCuentaDatosComponent implements OnInit {
  FormGroup: FormGroup;
  hideNueva = true;
  hideRepita = true;
  loading: boolean = false;
  errorValidaDatos: boolean;
  errorMensage: string;
  correoValido: string;
  crearCuentaDatosPeticion = new CrearCuentaDatosPeticion();
  preLoginsLS: { codTpoPortal: string; };
  /*regionSelect = [
    { option: 'Santiago', value: '1' }
  ];
  comunaSelect = [
    { option: 'Santiago', value: '1' }
  ];*/

  politicaPeticion = new PoliticaPeticion();
  tienePolitica: boolean;
  lrgMinPwd: string;
  lrgMaxPwd: string;
  canLetrasMayPwd: string;
  canLetrasMinPwd: string;
  canNumerosMinPwd: string;
  canCaracteresMinPwd: string;
  textoPolitica: string;
  disableButtom = false;
  movilValido: string;
  countryMovilUsuario: string;

  numeroMovilCompleto: string;
  numeroFijoCompleto: string;

  constructor(private dialog: MatDialog, private router: Router, private crearCuentaService: CrearCuentaService, @Inject('env') private environment: any, private autenticatorService: AutenticadorService) {
    this.movilValido = sessionStorage.getItem('movilValido');
    this.countryMovilUsuario = sessionStorage.getItem('countryMovilUsuario');
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.correoValido = sessionStorage.getItem("correoValido");
  }

  ngOnInit() {
    this.FormGroup = new FormGroup({
      nombre: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
      primerApellido: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
      segundoApellido: new FormControl('', Validators.compose([CustomValidators.nameValidator])),
      clave: new FormControl('', Validators.required),
      repetirClave: new FormControl('', Validators.required),
      codfijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2)])),
      fijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(9), Validators.minLength(8)])),
      codmovil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2), Validators.required])),
      movil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(9), Validators.minLength(8), Validators.required])),
      direccion: new FormControl('', Validators.compose([Validators.maxLength(250), , CustomValidators.descriptionValidator])),
    });

    this.politicaPeticion.codCliente = this.environment.codCliente;
    this.politicaPeticion.idPerfil = '0'; //Cero para que traiga la politica del portal habiente
    this.doPolitica(this.politicaPeticion);
  }

  doPolitica(body: PoliticaPeticion) {
    this.autenticatorService.doPolitica(body).subscribe(data => {
      if (data.codret == "0") {
        this.tienePolitica = true;
        if (data.lrgMinPwd == '0' && data.lrgMaxPwd == '0' && data.canLetrasMayPwd == '0' && data.canLetrasMinPwd == '0' && data.canNumerosMinPwd == '0' && data.canCaracteresMinPwd == '0') {
          this.textoPolitica = "No existen restricciones para crear una clave";
        } else {
          this.textoPolitica = "Políticas de restricciones para crear una clave:";
          if (data.lrgMinPwd != '0') {
            this.lrgMinPwd = "Longitud mínima: " + data.lrgMinPwd;
          }
          if (data.lrgMaxPwd != '0') {
            this.lrgMaxPwd = "Longitud máxima: " + data.lrgMaxPwd;
          }
          if (data.canLetrasMayPwd != '0') {
            this.canLetrasMayPwd = "Cantidad letras mayúsculas: " + data.canLetrasMayPwd;
          }
          if (data.canLetrasMinPwd != '0') {
            this.canLetrasMinPwd = "Cantidad letras minúsculas: " + data.canLetrasMinPwd;
          }
          if (data.canNumerosMinPwd != '0') {
            this.canNumerosMinPwd = "Cantidad mínima de números: " + data.canNumerosMinPwd;
          }
          if (data.canCaracteresMinPwd != '0') {
            this.canCaracteresMinPwd = "Cantidad mínima caracteres especiales: " + data.canCaracteresMinPwd;
          }
        }
      } else {
        this.errorValidaDatos = true;
        this.errorMensage = "" + data.descret;
        this.loading = false;
        this.disableButtom = true;
      }

    }, error => {
      this.errorValidaDatos = true;
      this.disableButtom = true;
      this.errorMensage = "Código de error: " + "(" + error.status + ") " + "Política de restricción de clave no encontrada.";
      this.loading = false;
    });
  }

  doValidaDatos(body: CrearCuentaDatosPeticion) {
    this.crearCuentaService.doValidaDatos(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        sessionStorage.setItem('crearCuentaDatos', JSON.stringify(data));
        this.router.navigate(['/crearcuentaexito']);
      } else {
        this.errorValidaDatos = true;
        this.errorMensage = "Código de error: " + data.descret + ".";
        this.loading = false;
      }
    }, error => {
      this.errorValidaDatos = true;
      this.errorMensage = "Código de error: " + error.statusText + ".";
      this.loading = false;
    });
  }

  validaDatos() {
    this.loading = true;
    if (this.FormGroup.get('clave').value == this.FormGroup.get('repetirClave').value) {
      this.crearCuentaDatosPeticion.codCliente = environment.codCliente;
      this.crearCuentaDatosPeticion.docEmpresa = environment.docEmpresa;
      this.crearCuentaDatosPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

      this.crearCuentaDatosPeticion.nombre = this.FormGroup.get('nombre').value;
      this.crearCuentaDatosPeticion.paterno = this.FormGroup.get('primerApellido').value;
      this.crearCuentaDatosPeticion.materno = this.FormGroup.get('segundoApellido').value;

      let codfijo: string = this.FormGroup.get('codfijo').value;
      let fonofijo: string = this.FormGroup.get('fijo').value;
      this.numeroFijoCompleto = codfijo + fonofijo;
      this.crearCuentaDatosPeticion.fonofijo = this.numeroFijoCompleto;

      let codmovil: string = this.FormGroup.get('codmovil').value;
      let fonomovil: string = this.FormGroup.get('movil').value;
      this.numeroMovilCompleto = codmovil + fonomovil;
      this.crearCuentaDatosPeticion.fonomovil = this.numeroMovilCompleto;

      this.crearCuentaDatosPeticion.direccion = this.FormGroup.get('direccion').value;

      this.crearCuentaDatosPeticion.email = this.correoValido;
      this.crearCuentaDatosPeticion.docUsrIde = "0";
      this.crearCuentaDatosPeticion.docUsrEmp = this.correoValido;
      this.crearCuentaDatosPeticion.claveUsuario = this.FormGroup.get('clave').value;
      this.crearCuentaDatosPeticion.idUsuario = '-1'; // En los casos de Adquirente y Comercio se envia el usuario logueado.

      this.doValidaDatos(this.crearCuentaDatosPeticion);
    } else {
      this.errorValidaDatos = true;
      this.errorMensage = "Código de error: la contraseña y confirmar contraseña no coinciden.";
      this.loading = false;
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('crearCuentaSms');
  }

  verPoliticasPrivacidad() {
    const dialogRef = this.dialog.open(DialogPoliticaPrivacidadComponent, {
      data: {},
      disableClose: true,
      maxHeight: '550px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
