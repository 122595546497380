import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

export class TerminoCondiciones {
    rut : string =  '';
    idEmpresa: string = "";
    nombre: string  = '';
    primerApellido : string = '';
    segundoApellido : string = '';
    idServicio : string = '';
    tarjeta : string = '';
    tarjetaTipo : string = '';
    fechaVctoTarjeta : string = '';
    fechaSubscripcion : string = '';
}