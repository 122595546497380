import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ValidaSmsService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('validaSms') != null) {
      return true;
    } else {
      // Si no esta activa la sesion, redirecciona al login
      this.router.navigate(['inicio']);
      return false;
    }

  }

}