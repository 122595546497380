import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CambioPassPeticion } from 'src/app/models/cambio-pass/cambioPass-peticion';
import { LoginPeticion } from 'src/app/models/login/login-peticion';
import { PoliticaPeticion } from 'src/app/models/politica/politica-peticion';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { environment } from 'src/environments/environment';
import { Renovartoken } from 'src/app/models/login/renovartoken';
import { Renovartokenresponse } from 'src/app/models/login/renovartokenresponse';
import { PreLoginResponse } from 'src/app/models/pre-login/PreLogin-response';
import { Router } from '@angular/router';
import { Embebido } from 'src/app/models/mandatos-express/apiEmbebidoPeticion';
import { EmbebidoResponse } from 'src/app/models/mandatos-express/apiEmbebidoResponse';
import { ValidaSesionPeticion } from 'src/app/models/mandatos-express/validasesionpeticion';
import { ValidaSesionResponse } from 'src/app/models/mandatos-express/validasesionresponse';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*'
    })
};

@Injectable()
export class AutenticadorService {
    urlPreLogin = environment.urlAmbiente + '/apiUsuarios/logincliente';
    urlLogin = environment.urlAmbiente + '/apiUsuarios/login';
    urlCambioPass = environment.urlAmbiente + '/apiUsuarios/cambioclave';
    urlPolitica = environment.urlAmbiente + '/apiUsuarios/traepolitica';
    urlRenovarToken = environment.urlAmbiente + '/apiUsuarios/renovartoken';
    urlCerrarSesion = environment.urlAmbiente + '/apiUsuarios/cerrarsesion';
    urlloginParExpress = environment.urlAmbiente + '/apiUsuarios/loginPAR';

    urlValidaSesion = environment.urlAmbiente + '/apiDatosPAT/validasesion';
    urlApiLoginEmb = environment.urlAmbiente + '/apiLoginEmb/embebido';

    constructor(private http: HttpClient, private router: Router) { }

    preLogin(params: PreLoginPeticion): Observable<PreLoginResponse> {
        return this.http.post<PreLoginResponse>(this.urlPreLogin, params, httpOptions);
    }

    doLogin(_body: LoginPeticion): Observable<any> {
        return this.http.post<any>(this.urlLogin, _body, httpOptions);
    }

    getLoginPar(): Observable<any> {
        return this.http.get<any>(this.urlloginParExpress, httpOptions);
    }

    doCambioPass(_body: CambioPassPeticion): Observable<any> {
        return this.http.post<any>(this.urlCambioPass, _body, httpOptions);
    }

    doPolitica(_body: PoliticaPeticion): Observable<any> {
        return this.http.post<any>(this.urlPolitica, _body, httpOptions);
    }

    renovarToken(params: Renovartoken): Observable<Renovartokenresponse> {
        return this.http.post<Renovartokenresponse>(this.urlRenovarToken, params, httpOptions);
    }

    cerrarSesion(params: Renovartoken): Observable<Renovartokenresponse> {
        return this.http.post<Renovartokenresponse>(this.urlCerrarSesion, params, httpOptions);
    }

    callPreLogin(redirect:boolean) {
        let params: PreLoginPeticion = new PreLoginPeticion();
        params.codCliente = environment.codCliente;
        params.urlPortal = environment.urlPortal;

        this.preLogin(params).subscribe(data => {
            if (data.codret == '0') {
                //console.log("PreLogin", data);
                sessionStorage.setItem('preLogin', JSON.stringify(data));
                console.log("data.estadoPortal", data.estadoPortal);
                if (data.estadoPortal == 'SUSPENDIDO') {
                    this.router.navigate(['/mantencion']);
                } else {
                    sessionStorage.setItem('tokenSession', data.tokenSession);
                    if(redirect){
                        this.router.navigate(['/inicio']);
                    }
                }
            } else {
                console.log('Error preLogin: ', data.descret);
                sessionStorage.setItem('preLoginError', JSON.stringify(data));
                this.router.navigate(['/mantencion']);
            }
        }, error => {
            sessionStorage.setItem('preLoginError', JSON.stringify(error));
            this.router.navigate(['/mantencion']);
        });
    }

    postValidaSesion(body: ValidaSesionPeticion): Observable<ValidaSesionResponse>{
        return this.http.post<ValidaSesionResponse>(this.urlValidaSesion, body, httpOptions);
    }

    postApiLoginEmb(params:Embebido):Observable<EmbebidoResponse> {
        return this.http.post<EmbebidoResponse>(this.urlApiLoginEmb,params,httpOptions);
    }

}