import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'moneda'})
export class MonedaStr implements PipeTransform {
  transform(value: any): string {
    let newStr: string = "$" + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return newStr;
  }
}

@Pipe({name: 'cantidad'})
export class CantidadStr implements PipeTransform {
  transform(value: string): string {
    let newStr: string = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return newStr;
  }
}

@Pipe({name: 'numberPipe'})
export class NumberStr implements PipeTransform {

  transform(val: any) {
    if(val){
    val = this.format_number(val.toString(), '');
    }
    return val;
  }

  format_number(number: string, prefix: string) {
    let thousand_separator = '.', decimal_separator = ',',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, ''),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      let separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] != undefined ? result + decimal_separator + split[1] : result;
    return prefix == undefined ? result : (result ? prefix + result : '');
  };

}

@Pipe({ name: 'milesSeparador' })
export class MilesSeparador implements PipeTransform {

  transform(value: number | string): string {
    if(!isNaN(Number(value))){
      const numero = Number(value);
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return '';
  }
}

@Pipe({
  name: 'numberMonedasPipe',
  pure: true
})
export class NumberMonedasStr implements PipeTransform {
  
  transform(val: any, moneda: any) {
    if(val === "0") {
      return val;
    }

    if(val){
      val = this.format_number(val.toString(), '', moneda);
    }
    return val;
  }

  format_number(number: string, prefix: string, tipoMoneda: string) {
    let decimales = "";
    let thousand_separator = '.';
    let decimal_separator = ',';
    let regexDecimal = new RegExp('[^' + decimal_separator + '\\d]', 'g');
    let regexMiles = new RegExp('[^' + thousand_separator + '\\d]', 'g');
    let number_string = number.replace(regexDecimal, '').replace(regexMiles, '');
    let lengthOriginal = number_string.length;
    
    number_string = Number(number_string) + "";
    let lengNumber = number_string.length;

    if(tipoMoneda == 'UF' || tipoMoneda == 'UTM'){
      if(number_string === "0"){
        if(lengthOriginal === 1){
          return "0";
        }
        if(lengthOriginal === 2){
          return "0,0";
        }
        if(lengthOriginal > 2){
          return "0";
        }
      }
      if(lengNumber < 2){
        return "0,0" + number_string;
      }
      if(lengNumber < 3){
        return "0," + number_string;
      }
      decimales = number_string.substring(lengNumber - 2, lengNumber);
      number_string = number_string.substring(0, lengNumber - 2);
    }
    
    let split = number_string.split(decimal_separator);
    let rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    let thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      let separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] != undefined ? result + decimal_separator + split[1] : result;
    let ret = prefix == undefined ? result : (result ? prefix + result : '');

    return decimales != "" ? ret + decimal_separator + decimales : ret;
  };
}

@Pipe({name: 'formatMonedaChilena'})
export class FormatMonedaChilePipe implements PipeTransform {
  transform(value: string, currency: string): string {
    // Eliminar caracteres no numéricos
    value = value.replace(/[^0-9,.]/g, '');
    
    // Si es moneda en pesos, aplicar formato con separador de miles
    if (currency === 'CLP') {
      return this.formatPesos(value);
    } else {
      return this.formatUfUtm(value);
    }
  }

  private formatPesos(value: string): string {
    return this.formatMiles(value);
  }

  private formatUfUtm(value: string): string {
    let parts = value.split(',');

    let parteEntera = parts[0];
    if(parteEntera.length > 10){
      parteEntera = parteEntera.substring(0, 10);
    }

    let partEntera = this.formatMiles(parteEntera);

    let decimalPart = '';
    if(parts.length > 1){
      decimalPart = parts[1];

      decimalPart = decimalPart.replace(/[^0-9]*/g, '');

      if(decimalPart.length > 2){
        decimalPart = "," + decimalPart.substring(0, 2);
      }else{
        decimalPart = "," + decimalPart;
      }
    }

    return partEntera + decimalPart;
  }

  private formatMiles(value: string){
    let regexDecimal = new RegExp('[^.\\d]', 'g');
    let regexMiles = new RegExp('[^,\\d]', 'g');

    let number_string = value.replace(regexDecimal, '').replace(regexMiles, '');
    number_string = Number(number_string) + "";

    let rest = number_string.length % 3;
    let result = number_string.substr(0, rest);
    let thousands = number_string.substr(rest).match(/\d{3}/g);

    if (thousands) {
      let separator = rest ? '.' : '';
      result += separator + thousands.join('.');
    }
    return  result ;
  }
}