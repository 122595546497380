import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crear-cuenta-exito',
  templateUrl: './crear-cuenta-exito.component.html',
  styleUrls: ['./crear-cuenta-exito.component.css']
})
export class CrearCuentaExitoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.removeItem('crearCuentaCorreo');
    sessionStorage.removeItem('crearCuentaClave');
    sessionStorage.removeItem('crearCuentaSms');
    sessionStorage.removeItem('crearCuentaDatos');
  }

}
