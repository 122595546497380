import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrearCuentaClavePeticion } from 'src/app/models/crear-cuenta/crearCuentaClave-peticion';
import { CrearCuentaCorreoPeticion } from 'src/app/models/crear-cuenta/crearCuentaCorreo-peticion';
import { CrearCuentaDatosPeticion } from 'src/app/models/crear-cuenta/crearCuentaDatos-peticion';
import { environment } from 'src/environments/environment';
import { UsuarioParameter } from 'src/app/models/editar-cuenta/usuario-parameter';
import { UsuarioResponse } from 'src/app/models/editar-cuenta/usuario-response';
import { UsuarioEditarParameter } from 'src/app/models/editar-cuenta/usuario-editar-parameter';
import { CrearCuentaValidaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaValidaSms-peticion';
import { CrearCuentaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaSms-peticion';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class CrearCuentaService {
  urlEnviaCorreo = environment.urlAmbiente + '/apiUsuarios/crearusuariocorreo';
  urlValidaClave = environment.urlAmbiente + '/apiUsuarios/validausuariocorreo';
  urlEnviaSms = environment.urlAmbiente + '/apiUsuarios/enviaUsuarioSms';
  urlValidaSms = environment.urlAmbiente + '/apiUsuarios/validaUsuarioSms';
  urlEnviaDatos = environment.urlAmbiente + '/apiUsuarios/crearusuario';
  urlObtenerUsuario = environment.urlAmbiente + '/apiUsuarios/obtenerusuario';
  urlEditarUsuario = environment.urlAmbiente + '/apiUsuarios/usuarioEditar';

  constructor(private http: HttpClient) { }

  doValidaCorreo(_body: CrearCuentaCorreoPeticion): Observable<any> {
    return this.http.post<any>(this.urlEnviaCorreo, _body, httpOptions);
  }
  doValidaClave(_body: CrearCuentaClavePeticion): Observable<any> {
    return this.http.post<any>(this.urlValidaClave, _body, httpOptions);
  }
  doEnviaSms(_body: CrearCuentaSmsPeticion): Observable<any> {
    return this.http.post<any>(this.urlEnviaSms, _body, httpOptions);
  }
  doValidaSms(_body: CrearCuentaValidaSmsPeticion): Observable<any> {
    return this.http.post<any>(this.urlValidaSms, _body, httpOptions);
  }
  doValidaDatos(_body: CrearCuentaDatosPeticion): Observable<any> {
    return this.http.post<any>(this.urlEnviaDatos, _body, httpOptions);
  }
  doObtenerUsuario(params: UsuarioParameter): Observable<UsuarioResponse> {
    return this.http.post<any>(this.urlObtenerUsuario, params, httpOptions);
  }
  doEditarUsuario(params: UsuarioEditarParameter): Observable<UsuarioResponse> {
    return this.http.post<any>(this.urlEditarUsuario, params, httpOptions);
  }
}