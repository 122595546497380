import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

   constructor(private router: Router) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      if(request.url.includes('Express')){
         let token = 'Bearer ' + sessionStorage.getItem('tokenSessionParExpress');
         let headers = new HttpHeaders().set("Authorization", token);
         let AuthRequest = request.clone({ headers: headers });
         return next.handle(AuthRequest);
      }


      if (sessionStorage.getItem('tokenSession') != null) {
         let token = 'Bearer ' + sessionStorage.getItem('tokenSession');
         let headers = new HttpHeaders().set("Authorization", token);
         let AuthRequest = request.clone({ headers: headers });

         return next.handle(AuthRequest).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                sessionStorage.removeItem('infoCambioPass');
                sessionStorage.removeItem('currentUser');
                sessionStorage.clear();
                sessionStorage.removeItem('login');
                this.router.navigate(['']);
            }

            let error = err.error.message || err.statusText;
            return throwError(error);
        }));
      } else {
         return next.handle(request);
      }
   }
}