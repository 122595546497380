import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recuperar-pass-exito',
  templateUrl: './recuperar-pass-exito.component.html',
  styleUrls: ['./recuperar-pass-exito.component.css']
})
export class RecuperarPassExitoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.removeItem('recuperarPassCorreoV');
    sessionStorage.removeItem('recuperarPassClaveV');
    sessionStorage.removeItem('recuperarPassValidaSmsV');
    sessionStorage.removeItem('recuperarPassCambioClaveV');
  }

}
