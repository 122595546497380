export class ValidationPanParams {
    public idcomercio:string;
    public trxmonto:string;
    public pan:string;
    public cvv:string;
    public fecexp:string;
    public cuotas:string;
    public trxmoneda:string;
    public secure3d:string;
}
