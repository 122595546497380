import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { CrearCuentaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaSms-peticion';
import { CrearCuentaValidaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaValidaSms-peticion';
import { UsuarioEditarParameter } from 'src/app/models/editar-cuenta/usuario-editar-parameter';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';
import { DialogBaseComponent } from '../../dialogs/dialog-base/dialog-base.component';

@Component({
  selector: 'app-editar-cuenta-valida-sms',
  templateUrl: './editar-cuenta-valida-sms.component.html',
  styleUrls: ['./editar-cuenta-valida-sms.component.css'],
  providers: [CrearCuentaService]
})
export class EditarCuentaValidaSmsComponent implements OnInit {
  formGroup: FormGroup;
  loading: boolean = false;
  loadingReenvio: boolean = false;
  errorValidaClave: boolean;
  errorMensage: string;
  crearCuentaValidaSmsPeticion = new CrearCuentaValidaSmsPeticion();
  crearCuentaSmsPeticion = new CrearCuentaSmsPeticion();
  movilValido: string;
  preLoginsLS: { codTpoPortal: string; };
  reenviar: boolean = true;
  editarusuarioparams: UsuarioEditarParameter;
  userPerfil: { usuario: { idUsuario: any; }; };
  idUsuario: any;
  countryMovilUsuario: string;
  celular: string;

  constructor(private router: Router, private crearCuentaService: CrearCuentaService, public dialog: MatDialog, @Inject('env') private environment: any) {
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.idUsuario = this.userPerfil.usuario.idUsuario;
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));

    this.editarusuarioparams = JSON.parse(sessionStorage.getItem('editarusuarioparams'));
    this.movilValido = this.editarusuarioparams.fonoMovil;
    this.countryMovilUsuario = sessionStorage.getItem('countryMovilUsuario');
    this.celular = sessionStorage.getItem('celular');
  }

  @ViewChild('countdown', null) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  ngOnInit() {
    this.formGroup = new FormGroup({
      clave: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')])),
    });
//    if (sessionStorage.getItem('reenviar') != null) {
//      this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
//    }

/*    if (sessionStorage.getItem('countDown') != null) {
      this.countdownConfig.leftTime = JSON.parse(sessionStorage.getItem('countDown')) / 1000;
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    } else { */
      this.countdownConfig.leftTime = 300; // 5 minutos - 300
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
/*    } */
  }

  validaSms() {
    this.loading = true;
    //this.crearCuentaValidaSmsPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaValidaSmsPeticion.docEmpresa = this.environment.docEmpresa;
    //this.crearCuentaValidaSmsPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.crearCuentaValidaSmsPeticion.claveSms = this.formGroup.get('clave').value;
    this.crearCuentaValidaSmsPeticion.movilUsuario = this.celular;
    this.crearCuentaValidaSmsPeticion.correoUsuario = sessionStorage.getItem("correoRecuperarPass");
    this.crearCuentaValidaSmsPeticion.tipoEnvio = 'CREARCUENTA';

    this.doValidaSms(this.crearCuentaValidaSmsPeticion);
  }

  doValidaSms(body: CrearCuentaValidaSmsPeticion) {
    this.crearCuentaService.doValidaSms(body).subscribe(data => {
      if (data.codret == '0') {
        this.loading = false;
//        sessionStorage.removeItem('countDown');
//        sessionStorage.removeItem('reenviar');
        sessionStorage.removeItem('countryMovilUsuario');
        sessionStorage.removeItem('celular');

        this.crearCuentaService.doEditarUsuario(this.editarusuarioparams).subscribe(data => {
          if (data.codret == '0') {
            sessionStorage.removeItem('movilUsuario');
            this.showDialogEditarOk('Información', 'Edición de usuario exitosa', false);
          } else {
            console.error("Error al editar usuario", data.descret);
            this.showDialogEditarFail('Error', 'No se ha podido validar su código de verificación.', false);
          }
        }, error => {
          console.error("Error callEditarUsuario", error);
          this.showDialogEditarFail('Error', 'Servicio temporalmente no disponible.', false);
        });


      } else {
        this.loading = false;
        if (data.codret == '-4') {
          this.showDialog('Error', data.descret, false);
        }
        this.errorValidaClave = true;
        this.errorMensage = 'Código de error: ' + data.descret;
      }
    }, error => {
      this.loading = false;
      this.showDialogEditarFail('Error', 'Servicio temporalmente no disponible.', false);
    });
  }

  getNotNullValue(formGroup: FormGroup, nameField: string): string {
    let resultado: string = formGroup.get(nameField).value;
    return (resultado == null) ? '' : resultado;
  }

  reenviarSms() {
//    sessionStorage.setItem('reenviar', 'true');
//    this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));

    this.crearCuentaSmsPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaSmsPeticion.docEmpresa = this.environment.docEmpresa;
    this.crearCuentaSmsPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.crearCuentaSmsPeticion.countryMovilUsuario = this.countryMovilUsuario;
    this.crearCuentaSmsPeticion.movilUsuario = this.celular;
    this.crearCuentaSmsPeticion.tipoEnvio = 'CAMBIACELULAR';

    this.doReenviarSms(this.crearCuentaSmsPeticion);
  }

  doReenviarSms(body: CrearCuentaSmsPeticion) {
    this.loadingReenvio = true;
    this.crearCuentaService.doEnviaSms(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        this.loadingReenvio = false
        console.log('Código reenviado por SMS');
      } else {
        this.loadingReenvio = false;
        this.showDialogEditarFail('Error', 'No se ha podido enviar el código de verificación por SMS.', false);
      }
    }, error => {
      this.showDialogEditarFail('Error', 'Servicio temporalmente no disponible.', false);
      this.loadingReenvio = false;
    });
  }

  onTimerFinished(e: Event) {
    if (e["action"] == "done") {
      this.showDialog('Límite de tiempo', 'Ha excedido el tiempo de validación de clave por celular.', false);
    } else {
//      sessionStorage.setItem('countDown', e['left']);
      if (e['text'] == '00:00:10') {
//        sessionStorage.setItem('reenviar', 'false');
//        this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
        this.reenviar = false;
      }
    }
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/home']);
      }
    });
  }
  showDialogEditarOk(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
        this.userPerfil['usuario']['nomUsuario'] = this.editarusuarioparams.nombre;
        sessionStorage.setItem('userPerfil', JSON.stringify(this.userPerfil));
        this.router.navigate(['/home']);
      }
    });
  }
  showDialogEditarFail(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/editarusuario']);
      }
    });
  }

  ngOnDestroy() {
  //  sessionStorage.removeItem('countDown');
//    sessionStorage.removeItem('reenviar');
  }

}
