export class CambioPassPeticion {
    codCliente: string;
    docEmpresa: string;
    docUsuario: string;
    claveActual: string;
    claveNueva: string;
    claveRepetida: string;
    codTpoPortal: string;

    tipoCambio: string;
}