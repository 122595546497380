import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { DialogBaseComponent } from 'src/app/components/dialogs/dialog-base/dialog-base.component';
import { CrearCuentaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaSms-peticion';
import { CrearCuentaValidaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaValidaSms-peticion';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';

@Component({
  selector: 'app-recuperar-pass-valida-sms',
  templateUrl: './recuperar-pass-valida-sms.component.html',
  styleUrls: ['./recuperar-pass-valida-sms.component.css'],
  providers: [CrearCuentaService]
})
export class RecuperarPassValidaSmsComponent implements OnInit {
  formGroup: FormGroup;
  loading: boolean = false;
  loadingReenvio: boolean = false;
  errorValidaClave: boolean;
  errorMensage: string;
  crearCuentaValidaSmsPeticion = new CrearCuentaValidaSmsPeticion();
  crearCuentaSmsPeticion = new CrearCuentaSmsPeticion();
  movilValido: string;
  preLoginsLS: { codTpoPortal: string; };
  reenviar: boolean = true;
  ultimoDigitoMovil: string = "";

  constructor(private router: Router, private crearCuentaService: CrearCuentaService, public dialog: MatDialog, @Inject('env') private environment: any) {
    this.movilValido = sessionStorage.getItem('movilValido');
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.ultimoDigitoMovil = JSON.parse(sessionStorage.getItem('recuperarPassClaveV')).movilUsuario;
  }

  @ViewChild('countdown', { static: true }) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  ngOnInit() {
    if(sessionStorage.getItem('conexionSmsFailed') != null){
      this.errorValidaClave = true;
      this.errorMensage = 'Código de error: ' + JSON.parse(sessionStorage.getItem('conexionSmsFailed'));
    }
    sessionStorage.removeItem('recuperarPassCorreoV');
    this.formGroup = new FormGroup({
      clave: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')])),
    });
    if (sessionStorage.getItem('reenviar') != null) {
      this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
    }

    if (sessionStorage.getItem('countDown') != null) {
      this.countdownConfig.leftTime = JSON.parse(sessionStorage.getItem('countDown')) / 1000;
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    } else {
      this.countdownConfig.leftTime = 300; // 5 minutos - 300
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    }
  }

  /* Valida codigo temporal */
  validaSms() {
    this.errorValidaClave = false;
    sessionStorage.removeItem('conexionSmsFailed');
    this.loading = true;
    //this.crearCuentaValidaSmsPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaValidaSmsPeticion.docEmpresa = this.environment.docEmpresa;
    //this.crearCuentaValidaSmsPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.crearCuentaValidaSmsPeticion.claveSms = this.formGroup.get('clave').value;
    this.crearCuentaValidaSmsPeticion.movilUsuario = "";
    this.crearCuentaValidaSmsPeticion.correoUsuario = sessionStorage.getItem("correoRecuperarPass");
    this.crearCuentaValidaSmsPeticion.tipoEnvio = 'RECUPERARCLAVE';

    this.doValidaSms(this.crearCuentaValidaSmsPeticion);
  }

  doValidaSms(body: CrearCuentaValidaSmsPeticion) {
    this.crearCuentaService.doValidaSms(body).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.removeItem('countDown');
        sessionStorage.removeItem('reenviar');
        sessionStorage.setItem('recuperarPassValidaSmsV', JSON.stringify(data));
        this.router.navigate(['/recuperarpasscambioclave']);
      } else {
        this.loading = false;
        if (data.codret == '-4') {
          this.showDialog('Error', data.descret, false);
        }
        this.errorValidaClave = true;
        this.errorMensage = 'Código de error: ' + data.descret;
      }
    }, error => {
      this.errorValidaClave = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
    });
  }

  /* Reenvia codigo temporal */
  reenviarSms() {
    this.errorValidaClave = false;
    sessionStorage.removeItem('conexionSmsFailed');
    sessionStorage.setItem('reenviar', 'true');
    this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));

    this.crearCuentaSmsPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaSmsPeticion.docEmpresa = this.environment.docEmpresa;
    this.crearCuentaSmsPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    //this.crearCuentaSmsPeticion.movilUsuario = this.movilValido.substring(2);
    //this.crearCuentaSmsPeticion.countryMovilUsuario = '+' + this.movilValido.substring(0,2);
    this.crearCuentaSmsPeticion.movilUsuario = "";
    this.crearCuentaSmsPeticion.countryMovilUsuario = "";
    this.crearCuentaSmsPeticion.email = sessionStorage.getItem('correoRecuperarPass');

    this.crearCuentaSmsPeticion.tipoEnvio = 'RECUPERARCLAVE';

    this.doReenviarSms(this.crearCuentaSmsPeticion);
  }

  doReenviarSms(body: CrearCuentaSmsPeticion) {
    this.loadingReenvio = true;
    this.crearCuentaService.doEnviaSms(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        this.loadingReenvio = false
        console.log('Código reenviado por SMS');
      } else {
        this.errorValidaClave = true;
        this.errorMensage = 'Código de error: ' + data.descret;
        this.loadingReenvio = false;
      }
    }, error => {
      this.errorValidaClave = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loadingReenvio = false;
    });
  }

  onTimerFinished(e: Event) {
    if (e["action"] == "done") {
      this.showDialog('Límite de tiempo', 'Ha excedido el tiempo de validación de clave por celular.', false);
    } else {
      sessionStorage.setItem('countDown', e['left']);
      if(e['text'] == '00:04:50'){
        sessionStorage.setItem('reenviar', 'false');
        this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
      }
    }
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/inicio']);
      }
    });
  }

}
