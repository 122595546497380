import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { CrearCuentaClavePeticion } from 'src/app/models/crear-cuenta/crearCuentaClave-peticion';
import { CrearCuentaCorreoPeticion } from 'src/app/models/crear-cuenta/crearCuentaCorreo-peticion';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';
import { DialogBaseComponent } from '../../dialogs/dialog-base/dialog-base.component';

@Component({
  selector: 'app-crear-cuenta-clave',
  templateUrl: './crear-cuenta-clave.component.html',
  styleUrls: ['./crear-cuenta-clave.component.css'],
  providers: [CrearCuentaService]
})
export class CrearCuentaClaveComponent implements OnInit {
  hideNueva = true;
  formGroup: FormGroup;
  loading: boolean = false;
  loadingReenvio: boolean = false;
  errorValidaClave: boolean;
  errorMensage: string;
  correoValido: string;
  movilValido: string;
  countryMovilUsuario: string;
  crearCuentaClavePeticion = new CrearCuentaClavePeticion();
  crearCuentaCorreoPeticion = new CrearCuentaCorreoPeticion();
  preLoginsLS: { codTpoPortal: string; };
  reenviar: boolean = true;


  constructor(private router: Router, private crearCuentaService: CrearCuentaService, public dialog: MatDialog, @Inject('env') private environment: any) {
    this.correoValido = sessionStorage.getItem('correoValido');
    this.movilValido = sessionStorage.getItem('movilValido');
    this.countryMovilUsuario = sessionStorage.getItem('countryMovilUsuario');
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
  }

  @ViewChild('countdown', null) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  ngOnInit() {
    sessionStorage.removeItem('conexionSmsFailed');
    this.formGroup = new FormGroup({
      clave: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')])),
    });

    if (sessionStorage.getItem('reenviar') != null) {
      this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
    }

    if (sessionStorage.getItem('countDown') != null) {
      this.countdownConfig.leftTime = JSON.parse(sessionStorage.getItem('countDown')) / 1000;
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    } else {
      this.countdownConfig.leftTime = 300; // 5 minutos - 300
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    }
  }

  /* Valida codigo temporal del correo */
  validaClave() {
    this.loading = true;
    //this.crearCuentaClavePeticion.codCliente = this.environment.codCliente;
    this.crearCuentaClavePeticion.docEmpresa = this.environment.docEmpresa;
    //this.crearCuentaClavePeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.crearCuentaClavePeticion.claveUsuario = this.formGroup.get('clave').value;
    this.crearCuentaClavePeticion.correoUsuario = this.correoValido;
    this.crearCuentaClavePeticion.movilUsuario = this.movilValido;
    this.crearCuentaClavePeticion.countryMovilUsuario = '+' + this.countryMovilUsuario;
    this.crearCuentaClavePeticion.tipoEnvio = 'CREARCUENTA';

    this.doValidaClave(this.crearCuentaClavePeticion);
  }

  doValidaClave(body: CrearCuentaClavePeticion) {
    this.crearCuentaService.doValidaClave(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '-90') {
        if(data.codret == '-90'){
          sessionStorage.setItem('conexionSmsFailed', JSON.stringify(data.descret));
        }
        sessionStorage.removeItem('countDown');
        sessionStorage.removeItem('reenviar');
        sessionStorage.setItem('crearCuentaClave', JSON.stringify(data));
        this.router.navigate(['/crearcuentavalidasms']);
      } else {
        this.loading = false;
        if (data.codret == '-4') {
          this.showDialog('Error', data.descret, false);
        }
        this.errorValidaClave = true;
        this.errorMensage = "Código de error: No se pudo validar el correo electrónico.";
        //console.log(data.descret);
        
      }
    }, error => {
      this.loading = false;
      this.showDialog('Error', 'Servicio temporalmente no disponible.', false);
    });
  }

/* reenvia codigo temporal de correo */
  reenviarCorreo() {
    sessionStorage.setItem('reenviar', 'true');
    this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));

    this.doReenviarCorreo();
  }
  doReenviarCorreo() {
    this.loadingReenvio = true;
    this.crearCuentaCorreoPeticion.correoUsuario = this.correoValido;
    this.crearCuentaCorreoPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaCorreoPeticion.docEmpresa = this.environment.docEmpresa;
    this.crearCuentaCorreoPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.crearCuentaService.doValidaCorreo(this.crearCuentaCorreoPeticion).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        this.loadingReenvio = false
        console.log('Codigo reenviado por correo');
      } else {
        this.errorValidaClave = true;
        this.errorMensage = 'Código de error: No se pudo enviar el código de verificación al correo.';
        this.loadingReenvio = false;
      }
    }, error => {
      this.loadingReenvio = false;
      this.showDialog('Error', 'Servicio temporalmente no disponible.', false);
    });
  }

  onTimerFinished(e: Event) {
    if (e["action"] == "done") {
      this.showDialog('Límite de tiempo', 'Ha excedido el tiempo de validación de clave por correo.', false);
    } else {
      sessionStorage.setItem('countDown', e['left']);
      if(e['text'] == '00:04:50'){
        sessionStorage.setItem('reenviar', 'false');
        this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
      }
    }
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/inicio']);
      }
    });
  }
  ngOnDestroy() {
    sessionStorage.removeItem('crearCuentaCorreo');
  }

}
