import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuloMandatosWebModule } from 'modulo-mandatos-web';
import { CambioPassComponent } from './components/autenticador/cambio-pass/cambio-pass.component';
import { InicioHabienteComponent } from './components/autenticador/inicio-habiente/inicio-habiente.component';
import { LoginComponent } from './components/autenticador/login/login.component';
import { MantencionComponent } from './components/autenticador/mantencion/mantencion.component';
import { RecuperarPassCambioClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-cambio-clave/recuperar-pass-cambio-clave.component';
import { RecuperarPassClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-clave/recuperar-pass-clave.component';
import { RecuperarPassCorreoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-correo/recuperar-pass-correo.component';
import { RecuperarPassExitoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-exito/recuperar-pass-exito.component';
import { CrearCuentaClaveComponent } from './components/crear-cuenta/crear-cuenta-clave/crear-cuenta-clave.component';
import { CrearCuentaCorreoComponent } from './components/crear-cuenta/crear-cuenta-correo/crear-cuenta-correo.component';
import { CrearCuentaDatosComponent } from './components/crear-cuenta/crear-cuenta-datos/crear-cuenta-datos.component';
import { CrearCuentaExitoComponent } from './components/crear-cuenta/crear-cuenta-exito/crear-cuenta-exito.component';
import { CrearCuentaValidaSmsComponent } from './components/crear-cuenta/crear-cuenta-valida-sms/crear-cuenta-valida-sms.component';
import { EditarCuentaValidaSmsComponent } from './components/editar-cuenta/editar-cuenta-valida-sms/editar-cuenta-valida-sms.component';
import { EditarCuentaComponent } from './components/editar-cuenta/editar-cuenta.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { ValidaCrearCuentaClaveService } from './services/crearCuenta/validaCrearCuentaClave.service';
import { ValidaCrearCuentaDatosService } from './services/crearCuenta/validaCrearCuentaDatos.service';
import { ValidaCrearCuentaExitoService } from './services/crearCuenta/validaCrearCuentaExito.service';
import { ValidaCrearCuentaSmsService } from './services/crearCuenta/validaCrearCuentaSms.service';
import { MantencionService } from './services/mantencion.service';
import { ValidaRecuperarPassCambioClaveService } from './services/recuperarPass/validaRecuperarPassCambioClave.service';
import { ValidaRecuperarPassClaveService } from './services/recuperarPass/validaRecuperarPassClave.service';
import { ValidaRecuperarPassCorreoService } from './services/recuperarPass/validaRecuperarPassCorreo.service';
import { ValidaRecuperarPassExitoService } from './services/recuperarPass/validaRecuperarPassExito.service';
import { SecurityService } from './services/security.service';
import { ValidaCambioPassService } from './services/validaCambioPass.service';
import { ValidaInicioService } from './services/validaInicio.service';
import { ValidaMenuService } from './services/validaMenu.service';
import { ValidaModuloService } from './services/validaModulo.service';
import { ValidaSmsService } from './services/validaSms.service';
import { ValidaRecuperarPassValidaSmsService } from './services/recuperarPass/validaRecuperarPassValidaSms.service';
import { RecuperarPassValidaSmsComponent } from './components/autenticador/recuperar-pass/recuperar-pass-valida-sms/recuperar-pass-valida-sms.component';
import { AddMandatosExpressComponent } from './components/add-mandatos-express/add-mandatos-express.component';
import { ValidaManExpressService } from './services/validaManExpress.service';


const routes: Routes = [
  { path: 'inicio', component: InicioHabienteComponent },

  { path: 'crearcuentacorreo', component: CrearCuentaCorreoComponent, canActivate: [ValidaInicioService] },
  { path: 'embeddedexpress', component: AddMandatosExpressComponent, canActivate: [ValidaManExpressService] },
  { path: 'crearcuentaclave', component: CrearCuentaClaveComponent, canActivate: [ValidaCrearCuentaClaveService] },
  { path: 'crearcuentavalidasms', component: CrearCuentaValidaSmsComponent, canActivate: [ValidaCrearCuentaSmsService] },
  { path: 'crearcuentadatos', component: CrearCuentaDatosComponent, canActivate: [ValidaCrearCuentaDatosService] },
  { path: 'crearcuentaexito', component: CrearCuentaExitoComponent, canActivate: [ValidaCrearCuentaExitoService] },

  { path: 'login', component: LoginComponent, canActivate: [ValidaInicioService] },
  { path: 'cambiopass', component: CambioPassComponent, canActivate: [ValidaCambioPassService] },
  { path: 'recuperarpasscorreo', component: RecuperarPassCorreoComponent, canActivate: [ValidaRecuperarPassCorreoService] },
  { path: 'recuperarpassclave', component: RecuperarPassClaveComponent, canActivate: [ValidaRecuperarPassClaveService] },
  { path: 'recuperarpassvalidasms', component: RecuperarPassValidaSmsComponent, canActivate: [ValidaRecuperarPassValidaSmsService] },
  { path: 'recuperarpasscambioclave', component: RecuperarPassCambioClaveComponent, canActivate: [ValidaRecuperarPassCambioClaveService] },
  { path: 'recuperarpassexito', component: RecuperarPassExitoComponent, canActivate: [ValidaRecuperarPassExitoService] },
  { path: 'mantencion', component: MantencionComponent, canActivate: [MantencionService] },
  {
    path: 'editarusuario',
    children: [
      { path: 'editarusuario', component: EditarCuentaComponent, canActivate: [ValidaCambioPassService] },
      { path: 'validasms', component: EditarCuentaValidaSmsComponent, canActivate: [ValidaSmsService] },
      { path: '', redirectTo:'editarusuario', pathMatch: 'full' }
    ]
  },

  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, canActivate: [SecurityService],
    children: [
      { path: '', redirectTo: 'habiente', pathMatch: 'full' },
      { path: 'habiente', component: MenuComponent, loadChildren: () => ModuloMandatosWebModule, canActivate: [ValidaModuloService], canActivateChild: [ValidaMenuService] },
      { path: '**', redirectTo: '' }
    ]
  },
  { path: '**', redirectTo: 'inicio' }//de otra manera redirecciona a inicio
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
