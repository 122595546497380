import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[soloRut]'
})
export class SoloRutDirective {

  constructor(
    private elem: ElementRef
  ) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const initalValue = this.elem.nativeElement.value;
    this.elem.nativeElement.value = initalValue.replace(/[^0-9kK]*/g, '');
    if (initalValue !== this.elem.nativeElement.value) {
      event.stopPropagation();
    }
  }
}


@Directive({
  selector: '[appFormatRut]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=> FormatRutDirective),
      multi: true
    }
  ]
})
export class FormatRutDirective {
  private onChange: (value: any) => void;
  private onTouch: () => void;

  constructor(private el: ElementRef){}

  ngOnInit(){
    const value = this.el.nativeElement.value;
    const formattedValue = this.formatRut(value);
    this.el.nativeElement.value = formattedValue;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string){
    const unformattedValue = this.unformatRut(value);
    this.el.nativeElement.value = unformattedValue;
    this.onChange(unformattedValue);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string){
    const unformattedValue = this.unformatRut(value);
    const formattedValue = this.formatRut(value);
    this.el.nativeElement.value = formattedValue;
    this.onChange(unformattedValue);
  }

  @HostListener('focus')
  onFocus(){
    const value = this.el.nativeElement.value;
    const unformattedValue = this.unformatRut(value);
    this.el.nativeElement.value = unformattedValue;
    this.onTouch();
  }

  private formatRut(rut: string): string {
    if(!rut) return '';

    rut = rut.replace(/[^\dKk]/g, '');

    if(rut.length < 2) return rut;

    const dv = rut.slice(-1);

    const rutNumbers = rut.slice(0, -1);

    const formattedRut = rutNumbers
      .split('')
      .reverse()
      .map((digit, index) => (index % 3 === 0 && index !== 0 ? `${digit}.` : digit))
      .reverse()
      .join('');

    return `${formattedRut}-${dv}`;
  }

  private unformatRut(rut: string): string {
    return rut.replace(/[^0-9kK]/g, '');
  }

  writeValue(value: any): void {
    const unformattedValue = this.unformatRut(value);
    const formattedValue = this.formatRut(unformattedValue);
    this.el.nativeElement.value = formattedValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}