import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ValidaManExpressService implements CanActivate {

  preLogin:any = JSON.parse(sessionStorage.getItem('preLogin'));

  constructor(
    private router: Router
  ) { }

  canActivate() {
    //TODO: Añadir logica de verificacion de ruta

    return true;
  }

}