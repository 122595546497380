export class EmpresasPeticion {
    public codCliente: string = '';
	public codFacturador: string = '-1';
	public nomFacturador: string = '-1';
	public docEmpresa: string = '-1';
	public idFacturador: number = -1;
	public codEstadoConvenio: string = '-1';
	public codEstadoEmpresa: string = '-1';
    public codRubro: string = '-1';
    public idUser: string = '-1';
    public tipoCobro: string = '-1';
}