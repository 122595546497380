import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class MantencionService implements CanActivate {
  preLogin: { estadoPortal: string; codret: string; };
  userPerfil: any;
  constructor(private router: Router) { }

  canActivate() {
    this.preLogin = JSON.parse(sessionStorage.getItem('preLogin'));
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    if (sessionStorage.getItem('preLoginError') || this.preLogin.estadoPortal == "SUSPENDIDO" ) {
      return true;
    }
    if (sessionStorage.getItem('userPerfil')) {
      if (this.userPerfil['perfil']['idStdPrf'] != 1) {
        return true;
      }
    }
    // Si no esta activa la sesion, redirecciona al prelogin
    this.router.navigate(['inicio']);
    return false;
  }
}