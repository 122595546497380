import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AutenticadorService } from './services/autenticador/autenticador.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AutenticadorService]
})
export class AppComponent {

  constructor(private titleService: Title) { }
  currentApplicationVersion = environment.appVersion;
  ngOnInit() {
    this.titleService.setTitle("Portal Pago Automático Getnet");
  }
}
