import { formatDate } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { TerminoCondiciones } from 'src/app/models/mandatos-express/condiciones';
import { TerminoCondicionesFinal } from 'src/app/models/mandatos-express/condicionesFinal';
import { CondicionesGeneralesService } from 'src/app/services/condiciones-generales/condiciones-generales.service';
import { Estilos } from 'src/app/services/estilos/estilos';


export interface DialogData {
  terminoCondiciones: TerminoCondiciones;
  terminoCondicionesFinal : TerminoCondicionesFinal;
  isEmbebido: boolean;
}

@Component({
  selector: 'lib-condiciones-generales',
  templateUrl: './condiciones-generales.component.html',
  styleUrls: ['./condiciones-generales.component.css']
})
export class CondicionesGeneralesComponent {

  contenidoParam : TerminoCondiciones;
  contenidoParamFinal : TerminoCondicionesFinal;

  private apiUrlPdf = "/apiMandatos/condicionesGenerales/pdf";
  private apiUrlPdfFinal ="/apiMandatos/condicionesGeneralesFinal/pdf"
  private apiUrlPdfFinalExpress ="/apiMandatos/condicionesGeneralesFinalExpress/pdf"

  html : string = "";
  idMandato : string = '0';
  isDataAvailable: boolean = false;
  isEmbebido: boolean = false;

  estilos : Estilos = new Estilos();

  constructor(
    public dialogRef: MatDialogRef<CondicionesGeneralesComponent>,
    private http: HttpClient,
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private condGenService: CondicionesGeneralesService
  ) {
      this.estilos.cambioEstilos(elementRef);
      this.contenidoParam = data.terminoCondiciones;
      this.contenidoParamFinal = data.terminoCondicionesFinal;
      this.isEmbebido = data.isEmbebido;
    }

  ngOnInit(): void {

    if(this.contenidoParamFinal.idMandato != ''){
      this.idMandato = this.contenidoParamFinal.idMandato;

      if(this.isEmbebido){
        this.condGenService.doCondicionesGeneralesFinal(this.contenidoParamFinal).subscribe(data => {
          if (data.codResp == "0") {
            this.isDataAvailable = true;
            this.html = data.html;
          }
        }, error => {
          console.log(error);
          console.log("Se cierre el popup");
          this.dialogRef.close();
        });

      }else{
        this.condGenService.doCondicionesGeneralesFinalExpress(this.contenidoParamFinal).subscribe(data => {
          if (data.codResp == "0") {
            this.isDataAvailable = true;
            this.html = data.html;
          }
        }, error => {
          console.log(error);
          console.log("Se cierre el popup");
          this.dialogRef.close();
        });
      }
      
    }else{

      if(this.isEmbebido){
        this.condGenService.doCondicionesGenerales(this.contenidoParam).subscribe(data => {
          if (data.codResp == "0") {
            this.isDataAvailable = true;
            this.html = data.html;
          }
        }, error => {
          console.log(error);
          console.log("Se cierre el popup");
          this.dialogRef.close();
        });

      }else{
        this.condGenService.doCondicionesGeneralesExpress(this.contenidoParam).subscribe(data => {
          if (data.codResp == "0") {
            this.isDataAvailable = true;
            this.html = data.html;
          }
        }, error => {
          console.log(error);
          console.log("Se cierre el popup");
          this.dialogRef.close();
        });
      }
    }

  }

  downloadReportService(filtro: any,api : string): Observable<HttpEvent<Blob>> {
      return this.http.request(new HttpRequest(
        'POST',api , filtro,
        {
          reportProgress: true,
          responseType: 'blob'
        }));
  }

  public postDownloadReportFile(filtro: any) {
    let apiUrl = this.apiUrlPdf;
    if(this.idMandato != '0'){
      apiUrl = this.isEmbebido ? this.apiUrlPdfFinal : this.apiUrlPdfFinalExpress;
    }
    this.downloadReportService(filtro, apiUrl).subscribe(data => {
      if (data.type == HttpEventType.Response) {
        let downloadedFile: Blob;
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        downloadedFile = new Blob([data.body], { type: 'application/pdf;charset=utf-8' });
         a.download = 'Termino_Condiciones_' + formatDate(new Date(), 'yyyy/MM/dd', 'en') + '.pdf';
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      }
    },
      err => {
         console.log(err);
      }
    );
  }
   
    onNoClick(): void {
      this.dialogRef.close();
    }

    descargarPDF() {
      if(this.contenidoParamFinal.idMandato != '') {
        this.postDownloadReportFile(this.contenidoParamFinal);
      }else {
        this.postDownloadReportFile(this.contenidoParam);
      }
    }

}