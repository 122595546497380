import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CardinalTokenParams } from 'src/app/models/mandatos-express/cardinal-token-params';
import { CardinalTokenResponse } from 'src/app/models/mandatos-express/cardinal-token-response';
import { ValidationPanParams } from 'src/app/models/mandatos-express/validation-trx-params';
import { ValidationPanResponse } from 'src/app/models/mandatos-express/validation-trx-response';
import { ConsultaBinParams } from 'src/app/models/mandatos-express/consulta-bin-params';
import { ConsultaBinResponse } from 'src/app/models/mandatos-express/consulta-bin-response';
import { ConsultaIdServicioParams } from 'src/app/models/mandatos-express/consulta-idservicio-params';
import { ConsultaIdServicioResponse } from 'src/app/models/mandatos-express/consulta-idservicio-response';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CardinalCommerceService {

  cardinalParams: CardinalTokenParams = new CardinalTokenParams();

  constructor(
    private http: HttpClient, 
    @Inject('env') private environment: any
  ) { }

  urlGenCardTkns = this.environment.urlAmbiente + '/apiTransaccion/generateCardinalToken';
  urlGenCardTknsExpress = this.environment.urlAmbiente + '/apiTransaccion/genCardTkExpress';
  urlValTarjeta = this.environment.urlAmbiente + '/apiTransaccion/validartarjeta';
  urlValTarjetaExpress = this.environment.urlAmbiente + '/apiTransaccion/valTarjExpress';

  getTokenCardinal(codCliente:string, docEmpresa:string, amount:string, currencyCode:string, isEmbebido: boolean): Observable<CardinalTokenResponse> {

    this.cardinalParams.referenceId = '123456789';
    this.cardinalParams.confirmUrl = this.environment.urlAmbiente;
    this.cardinalParams.amount = amount;
    this.cardinalParams.currencyCode = currencyCode;
    this.cardinalParams.codCliente = codCliente;
    this.cardinalParams.docEmpresa = docEmpresa;

    let url = isEmbebido ? this.urlGenCardTkns : this.urlGenCardTknsExpress;
    return this.http.post<CardinalTokenResponse>(url, this.cardinalParams, httpOptions);

  }

  validacionPan(validatePanParams: ValidationPanParams, isEmbebido: boolean) {
    let url = isEmbebido ? this.urlValTarjeta : this.urlValTarjetaExpress;
    return this.http.post<ValidationPanResponse>(url, validatePanParams, httpOptions);
  }

  consultaBin(params: ConsultaBinParams){
    return this.http.post<ConsultaBinResponse>(this.environment.urlAmbiente + '/apiTransaccion/consultaBin', params, httpOptions);
  }

}
