import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecuperarPassCorreoPeticion } from 'src/app/models/recuperar-pass/recuperarPassCorreo-peticion';
import { RecuperarPassService } from 'src/app/services/recuperarPass/recuperar-pass.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recuperar-pass-correo',
  templateUrl: './recuperar-pass-correo.component.html',
  styleUrls: ['./recuperar-pass-correo.component.css'],
  providers: [RecuperarPassService]
})
export class RecuperarPassCorreoComponent implements OnInit {
  FormGroup: FormGroup;
  loading: boolean = false;
  errorValidaCorreo: boolean;
  errorMensage: string;
  recuperarPassCorreoPeticion = new RecuperarPassCorreoPeticion();
  preLoginsLS: { codTpoPortal: string; };

  constructor(private router: Router, private recuperarPassService: RecuperarPassService) {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
  }

  ngOnInit() {
    sessionStorage.removeItem('recuperarPassCorreoV');
    sessionStorage.removeItem('reenviar');
    sessionStorage.removeItem('countDown');

    this.FormGroup = new FormGroup({
      correo: new FormControl('', Validators.compose([Validators.email, Validators.required])),
    });
  }

  doRecuperarPassCorreo(body: RecuperarPassCorreoPeticion) {
    this.recuperarPassService.doRecuperarCorreo(body).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.setItem('recuperarPassCorreoV', JSON.stringify(data));
        sessionStorage.setItem('correoRecuperarPass', this.FormGroup.get('correo').value);
        //sessionStorage.setItem('movilValido', data.movilUsuario);
        this.router.navigate(['/recuperarpassclave']);
      } else {
        this.errorValidaCorreo = true;
        this.errorMensage = data.descret;
        this.loading = false;
      }
    }, error => {
      this.errorValidaCorreo = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
    });
  }
  recuperarPassCorreo() {
    this.loading = true;
    this.recuperarPassCorreoPeticion.correoUsuario = this.FormGroup.get('correo').value;
    //this.recuperarPassCorreoPeticion.codCliente = environment.codCliente;
    this.recuperarPassCorreoPeticion.docEmpresa = environment.docEmpresa;
    //this.recuperarPassCorreoPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.doRecuperarPassCorreo(this.recuperarPassCorreoPeticion);
  }

}
