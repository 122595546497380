import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskCardNumber'
})
export class MaskCardNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let mask: string = '**** **** **** ';
    let maskedcard: string = value.toString();
    let result: string = mask + maskedcard.substr((maskedcard.length - 4), 4);
    return result.substr(0,19);
  }

}
