import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoginPeticion } from 'src/app/models/login/login-peticion';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { environment } from 'src/environments/environment';
import { DialogBaseComponent } from '../../dialogs/dialog-base/dialog-base.component';
import { DialogPoliticaPrivacidadComponent } from '../../dialogs/dialog-politica-privacidad/dialog-politica-privacidad.component';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AutenticadorService]
})
export class LoginComponent implements OnInit {
  hide = true;
  FormGroup: FormGroup;
  loading = false;
  errorLogin: boolean;
  errorMensage = '';
  preLoginsLS: { codTpoPortal: string; };

  loginPeticion = new LoginPeticion();
  
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private autenticatorService: AutenticadorService
  ) { }

  ngOnInit() {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));

    this.FormGroup = new FormGroup({
      correo: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      clave: new FormControl('', Validators.required)
    });
  }

  doLogin(body: LoginPeticion) {
    this.autenticatorService.doLogin(body).subscribe(data => {
      if (data.codret == '0') {
        //console.log('userPerfil: ',data);
        sessionStorage.setItem('userPerfil', JSON.stringify(data));
        if (data['perfil']['idStdPrf'] != 1) {
          this.router.navigate(['/mantencion']);
        } else {
          sessionStorage.setItem('docUsuario', this.FormGroup.get('correo').value);
          sessionStorage.setItem('idPerfil', data['perfil']['idPerfil']);
          if (data['usuario']['codEstado'] == 'CAMBIOPASS') {
            this.router.navigate(['/cambiopass']);
          } else {
            sessionStorage.setItem('currentUser', data['usuario']);
            sessionStorage.setItem('tokenSession', data['tokenSession']);
            sessionStorage.setItem('minExpSession', data['perfil']['polMinExpSesion']);
            this.router.navigate(['/home']);
          }
        }
      } else {
        if (data.codret == '-7') {
          //Se pregunta si quiere eliminar las otras sesiones
          this.showDialog("Error", "Existe una sesión abierta en otro lugar. ¿Desea cerrar la otra sesión?", true);
        }
        this.errorLogin = true;
        this.errorMensage = "Código de error: " + data.descret;
        this.loading = false;
      }
    }, error => {
      this.errorLogin = true;
      this.errorMensage = error.statusText;
      this.loading = false;
    });
  }

  login() {
    this.loading = true;
    sessionStorage.setItem("date", Date());
    this.loginPeticion.docEmpresa = environment.docEmpresa;
    this.loginPeticion.docUsuario = this.FormGroup.get('correo').value;
    this.loginPeticion.claveUsuario = this.FormGroup.get('clave').value;
    this.loginPeticion.codCliente = environment.codCliente;
    this.loginPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    //Antes de invocar al servicio se renueva el token de aplicacion
    let params: PreLoginPeticion = new PreLoginPeticion();
    params.codCliente = environment.codCliente;
    params.urlPortal = environment.urlPortal;

    this.autenticatorService.preLogin(params).subscribe(data => {
      if (data.codret == '0') {
        if (data.estadoPortal == 'SUSPENDIDO') {
          this.router.navigate(['/mantencion']);
        } else {
          sessionStorage.setItem('tokenSession', data.tokenSession);
          this.doLogin(this.loginPeticion);
        }
      } else {
        sessionStorage.setItem('preLoginError', JSON.stringify(data));
        this.router.navigate(['/mantencion']);
      }
    }, error => {
      sessionStorage.setItem('preLoginError', JSON.stringify(error));
      this.router.navigate(['/mantencion']);
    });
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      let respuesta: string = result.toString();
      console.log('The dialog was closed ' + respuesta);
      if (respuesta == 'true') {
        this.loginPeticion.cierraOtraSesion = "S";
        this.login();
      } else {
        this.loginPeticion.cierraOtraSesion = "N";
      }
    });
  }

  verPoliticasPrivacidad() {
    const dialogRef = this.dialog.open(DialogPoliticaPrivacidadComponent, {
      data: {},
      disableClose: true,
      maxHeight: '550px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
