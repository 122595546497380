import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PoliticaPeticion } from 'src/app/models/politica/politica-peticion';
import { RecuperarPassCambioClavePeticion } from 'src/app/models/recuperar-pass/recuperarPassCambioClave-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { RecuperarPassService } from 'src/app/services/recuperarPass/recuperar-pass.service';

@Component({
  selector: 'app-recuperar-pass-cambio-clave',
  templateUrl: './recuperar-pass-cambio-clave.component.html',
  styleUrls: ['./recuperar-pass-cambio-clave.component.css'],
  providers: [RecuperarPassService, AutenticadorService]
})
export class RecuperarPassCambioClaveComponent implements OnInit {
  FormGroup: FormGroup;
  hideNueva = true;
  hideRepita = true;
  loading: boolean = false;
  errorValidaDatos: boolean;
  errorMensage: string;
  recuperarPassClaveActual: string;
  preLoginsLS: { codTpoPortal: string; };
  correoRecuperarPass: string;
  recuperarPassCambioClavePeticion = new RecuperarPassCambioClavePeticion();

  politicaPeticion = new PoliticaPeticion();
  tienePolitica: boolean;
  lrgMinPwd: string;
  lrgMaxPwd: string;
  canLetrasMayPwd: string;
  canLetrasMinPwd: string;
  canNumerosMinPwd: string;
  canCaracteresMinPwd: string;
  textoPolitica: string;
  disableButtom = false;

  constructor(private router: Router, private recuperarPassService: RecuperarPassService, @Inject('env') private environment: any, private autenticatorService: AutenticadorService) {
    this.recuperarPassClaveActual = sessionStorage.getItem("recuperarPassClaveActual");
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.correoRecuperarPass = sessionStorage.getItem("correoRecuperarPass");
  }

  ngOnInit() {
    sessionStorage.removeItem('recuperarPassClaveV');
    this.FormGroup = new FormGroup({
      clave: new FormControl('', Validators.required),
      repetirClave: new FormControl('', Validators.required)
    });

    //Antes de invocar al servicio se renueva el token de aplicacion
    this.autenticatorService.callPreLogin(false);

    this.politicaPeticion.codCliente = this.environment.codCliente;
    this.politicaPeticion.idPerfil = '0';
    this.doPolitica(this.politicaPeticion);
  }

  doPolitica(body: PoliticaPeticion) {
    this.autenticatorService.doPolitica(body).subscribe(data => {
      if (data.codret == "0") {
        this.tienePolitica = true;
        if (data.lrgMinPwd == '0' && data.lrgMaxPwd == '0' && data.canLetrasMayPwd == '0' && data.canLetrasMinPwd == '0' && data.canNumerosMinPwd == '0' && data.canCaracteresMinPwd == '0') {
          this.textoPolitica = "No existen restricciones para crear una clave";
        } else {
          this.textoPolitica = "Políticas de restricciones para crear una clave:";
          if (data.lrgMinPwd != '0') {
            this.lrgMinPwd = "Longitud mínima: " + data.lrgMinPwd;
          }
          if (data.lrgMaxPwd != '0') {
            this.lrgMaxPwd = "Longitud máxima: " + data.lrgMaxPwd;
          }
          if (data.canLetrasMayPwd != '0') {
            this.canLetrasMayPwd = "Cantidad letras mayúsculas: " + data.canLetrasMayPwd;
          }
          if (data.canLetrasMinPwd != '0') {
            this.canLetrasMinPwd = "Cantidad letras minúsculas: " + data.canLetrasMinPwd;
          }
          if (data.canNumerosMinPwd != '0') {
            this.canNumerosMinPwd = "Cantidad mínima de números: " + data.canNumerosMinPwd;
          }
          if (data.canCaracteresMinPwd != '0') {
            this.canCaracteresMinPwd = "Cantidad mínima caracteres especiales: " + data.canCaracteresMinPwd;
          }
        }
      } else {
        console.error('Error servicio traePolitica', data.codret, data.descret)
        this.errorValidaDatos = true;
        this.errorMensage = "Política de restricción de clave no encontrada.";
        this.loading = false;
        this.disableButtom = true;
      }

    }, error => {
      this.errorValidaDatos = true;
      this.disableButtom = true;
      this.errorMensage = 'Código de error: Política de restricción de clave no encontrada.';
      this.loading = false;
    });
  }

  doRecuperarPassCambioClave(body: RecuperarPassCambioClavePeticion) {
    this.recuperarPassService.doRecuperarPassCambioClave(body).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.setItem('recuperarPassCambioClaveV', JSON.stringify(data));
        this.router.navigate(['/recuperarpassexito']);
      } else {
        this.errorValidaDatos = true;
        this.errorMensage = 'Código de error: ' + data.descret;
        this.loading = false;
      }
    }, error => {
      this.errorValidaDatos = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
    });
  }

  recuperarPassCambioClave() {
    this.loading = true;
    if (this.FormGroup.get('clave').value == this.FormGroup.get('repetirClave').value) {

      this.recuperarPassCambioClavePeticion.codCliente = this.environment.codCliente;
      this.recuperarPassCambioClavePeticion.docEmpresa = this.environment.docEmpresa;
      this.recuperarPassCambioClavePeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
      this.recuperarPassCambioClavePeticion.docUsuario = this.correoRecuperarPass;
      this.recuperarPassCambioClavePeticion.claveActual = this.recuperarPassClaveActual;
      this.recuperarPassCambioClavePeticion.claveNueva = this.FormGroup.get('clave').value;
      this.recuperarPassCambioClavePeticion.claveRepetida = this.FormGroup.get('repetirClave').value;

      this.recuperarPassCambioClavePeticion.tipoCambio = 'T';

      this.doRecuperarPassCambioClave(this.recuperarPassCambioClavePeticion);
    } else {
      this.errorValidaDatos = true;
      this.errorMensage = 'Código de error: nueva contraseña no coincide con confirmar contraseña.';
      this.loading = false;
    }

  }

}
