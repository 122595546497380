import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreLoginPeticion } from '../../../models/pre-login/PreLogin-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';

@Component({
  selector: 'app-inicio-habiente',
  templateUrl: './inicio-habiente.component.html',
  styleUrls: ['./inicio-habiente.component.css']
})
export class InicioHabienteComponent implements OnInit {
  errorLogin: boolean;
  versionPortal: any;

  preLoginPeticion = new PreLoginPeticion();

  constructor(private autenticatorService: AutenticadorService, private router: Router) { }

  ngOnInit() {
    sessionStorage.clear();
    this.autenticatorService.callPreLogin(true);
  }

  crearCuenta() {
    this.router.navigate(['/crearcuentacorreo']);
  }

  suscManExpress(){
    this.router.navigate(['/embeddedexpress']);
  }
  
  acceso() {
    this.router.navigate(['/login']);
  }

}
