import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  title: string;
  content: string;
  showBtnCancel:boolean;
  btnCancelar: string;
  btnAceptar: string;
}

@Component({
  selector: 'lib-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.css']
})
export class DialogBaseComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    //console.log('Botones> ' + data.btnAceptar + ' ' + data.btnCancelar);
  }

    onClose(): void {
      this.dialogRef.close();
    }
}
