export class UsuarioEditarParameter {
	public idPerfil: number = 0;
	public codEstadoUserEmp: string = 'ACTIVO';
	public nombre: string = '';
	public apellidoPaterno: string = '';
	public apellidoMaterno: string = '';
	public email: string = '';
	public fonoFijo: string = '';
	public cargo: string = '';
	public fonoMovil: string = '';
	public direccion: string = '';
	public codCliente: string = '';
	public docEmpresa: string = '';
	public docUsrEmp: string = '';
	public codTpoPortal: string = '';
	public idUsuario: string; //idUsuario logueado para el caso del Adquirente y Comercio al crear un usuario.
}
