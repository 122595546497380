import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CrearCuentaSmsPeticion } from 'src/app/models/crear-cuenta/crearCuentaSms-peticion';
import { UsuarioEditarParameter } from 'src/app/models/editar-cuenta/usuario-editar-parameter';
import { UsuarioParameter } from 'src/app/models/editar-cuenta/usuario-parameter';
import { CrearCuentaService } from 'src/app/services/crearCuenta/crear-cuenta.service';
import { CustomValidators } from 'src/app/util/custom-validators';
import { DialogBaseComponent } from '../dialogs/dialog-base/dialog-base.component';


@Component({
  selector: 'app-editar-cuenta',
  templateUrl: './editar-cuenta.component.html',
  styleUrls: ['./editar-cuenta.component.css'],
  providers: [CrearCuentaService]
})
export class EditarCuentaComponent implements OnInit {

  editarForm = new FormGroup({
    docusuario: new FormControl({ value: '', disabled: true }, Validators.required),
    nombre: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
    apellidopaterno: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
    apellidomaterno: new FormControl('', Validators.compose([CustomValidators.nameValidator])),
    codfijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2)])),
    fonofijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(9), Validators.minLength(8)])),
    codmovil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2), Validators.required])),
    fonomovil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(9), Validators.minLength(8), Validators.required])),
    direccion: new FormControl('', Validators.compose([Validators.maxLength(250), CustomValidators.descriptionValidator])),
    emailusuario: new FormControl('')
  });

  errorMensage: string = '';
  userPerfil: any;
  idUsuario: any;
  crearCuentaSmsPeticion = new CrearCuentaSmsPeticion();
  editarusuarioparams = new UsuarioEditarParameter();
  preLoginsLS: { codTpoPortal: string; };
  reenviar: boolean = false;
  loading: boolean = false;
  numeroMovilCompleto: string;
  numeroFijoCompleto: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private crearCuentaService: CrearCuentaService,
    @Inject('env') private environment: any
  ) {
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.idUsuario = this.userPerfil.usuario.idUsuario;
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
  }

  ngOnInit() {
    if (sessionStorage.getItem('editarusuarioparams') != null) {
      this.editarusuarioparams = JSON.parse(sessionStorage.getItem('editarusuarioparams'));
      this.editarForm.get('docusuario').setValue(sessionStorage.getItem('docUsuario'));
      this.editarForm.get('nombre').setValue(this.editarusuarioparams.nombre);
      this.editarForm.get('apellidopaterno').setValue(this.editarusuarioparams.apellidoPaterno);
      this.editarForm.get('codmovil').setValue(this.setCodTelefono(this.editarusuarioparams.fonoMovil));
      this.editarForm.get('fonomovil').setValue(this.setNumTelefono(this.editarusuarioparams.fonoMovil));
      this.editarForm.get('apellidomaterno').setValue(this.editarusuarioparams.apellidoMaterno);
      this.editarForm.get('direccion').setValue(this.editarusuarioparams.direccion);
      this.editarForm.get('codfijo').setValue(this.setCodTelefono(this.editarusuarioparams.fonoFijo));
      this.editarForm.get('fonofijo').setValue(this.setNumTelefono(this.editarusuarioparams.fonoFijo));
      this.editarForm.get('emailusuario').setValue(this.editarusuarioparams.email);
    } else {
      this.callObtenerUsuario();
    }
  }

  setCodTelefono(numero:string){
    let codigo:string = '';
    if(numero != null){
      if(numero.length != 0)
        codigo = (numero == 'null') ? '' : numero.substr(0,2);
    }
    return codigo;
  }
  setNumTelefono(numero:string){
    let numeroaparte:string = ''; 
    if(numero != null){
      if(numero.length != 0)
        numeroaparte = (numero == 'null') ? '' : numero.substr(2);
    }
    return numeroaparte;
  }

  callEditarUsuario() {
    console.log("Datos form: " + this.editarForm.valid);
    if (this.editarForm.valid) {

      this.loading = true;
      //Datos para identificar el usuario
      this.editarusuarioparams.codCliente = this.environment.codCliente;
      this.editarusuarioparams.codTpoPortal = this.environment.tipoPortal;
      this.editarusuarioparams.docEmpresa = this.environment.docEmpresa;
      this.editarusuarioparams.docUsrEmp = sessionStorage.getItem('docUsuario');
      this.editarusuarioparams.idPerfil = +sessionStorage.getItem('idPerfil');
      this.editarusuarioparams.idUsuario = this.idUsuario;

      //Datos para modificar obligarotios
      this.editarusuarioparams.nombre = this.editarForm.get('nombre').value;
      this.editarusuarioparams.apellidoPaterno = this.editarForm.get('apellidopaterno').value;
      let codmovil: string = this.editarForm.get('codmovil').value;
      let fonomovil: string = this.editarForm.get('fonomovil').value;
      this.numeroMovilCompleto = codmovil + fonomovil;
      this.editarusuarioparams.fonoMovil = this.numeroMovilCompleto;

      //Datos para modificar no obligatorios
      this.editarusuarioparams.apellidoMaterno = this.getNotNullValue(this.editarForm, 'apellidomaterno');
      this.editarusuarioparams.direccion = this.getNotNullValue(this.editarForm, 'direccion');
      let codfijo: string = this.editarForm.get('codfijo').value;
      let fonofijo: string = this.editarForm.get('fonofijo').value;
      this.numeroFijoCompleto = codfijo + fonofijo;
      this.editarusuarioparams.fonoFijo = this.numeroFijoCompleto;
      this.editarusuarioparams.email = this.getNotNullValue(this.editarForm, 'docusuario');

      console.log("Telefonos " + this.editarusuarioparams.fonoMovil +" "+ JSON.parse(sessionStorage.getItem('movilUsuario')));
      if (this.editarusuarioparams.fonoMovil == JSON.parse(sessionStorage.getItem('movilUsuario'))) {
        this.crearCuentaService.doEditarUsuario(this.editarusuarioparams).subscribe(data => {
          if (data.codret == '0') {
            this.loading = false;
            sessionStorage.removeItem('movilUsuario');
            console.log("Edición de usuario exitosa");
            this.showDialog('dialogExitoso', 'Información', 'Edición de usuario exitosa.', false);
          } else {
            this.loading = false;
            console.error("Error al editar usuario", data.descret);
            this.showDialogStop('Error', 'Problemas para editar usuario.<br>Intente más tarde o contáctese con administrador de sistema.', false);
          }
        }, error => {
          this.loading = false;
          console.error("Error callEditarUsuario", error);
          this.showDialogFail('Error', 'Servicio temporalmente no disponible.', false);
        });
      } else {
        sessionStorage.setItem('validaSms', JSON.stringify(this.numeroMovilCompleto));
        sessionStorage.setItem('editarusuarioparams', JSON.stringify(this.editarusuarioparams));
        sessionStorage.setItem('countryMovilUsuario', '+' + this.editarForm.get('codmovil').value);
        sessionStorage.setItem('celular', this.editarForm.get('fonomovil').value);
        this.enviarSms();
      }
    }
  }

  enviarSms() {
    this.crearCuentaSmsPeticion.codCliente = this.environment.codCliente;
    this.crearCuentaSmsPeticion.docEmpresa = this.environment.docEmpresa;
    this.crearCuentaSmsPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.crearCuentaSmsPeticion.countryMovilUsuario = '+' + this.editarForm.get('codmovil').value;
    this.crearCuentaSmsPeticion.movilUsuario = this.editarForm.get('fonomovil').value;
    this.crearCuentaSmsPeticion.tipoEnvio = 'CAMBIACELULAR';

    this.doReenviarSms(this.crearCuentaSmsPeticion);
  }

  doReenviarSms(body: CrearCuentaSmsPeticion) {
    this.crearCuentaService.doEnviaSms(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '1') {
        this.loading = false;
        console.log('Código enviado por SMS');
        this.router.navigate(['../validasms'], { relativeTo: this.route });
      } else {
        this.loading = false;
        this.errorMensage = 'Código de error: ' + data.descret;
        this.showDialogStop('Error', 'Problemas para editar usuario.<br>Intente más tarde o contáctese con el administrador de sistema.', false);
      }
    }, error => {
      this.loading = false;
      this.errorMensage = 'Código de error: ' + error.statusText + '.';
      this.showDialogFail('Error', 'Servicio temporalmente no disponible.', false);
    });
  }

  callObtenerUsuario() {
    let usuarioparams: UsuarioParameter = new UsuarioParameter();
    usuarioparams.codCliente = this.environment.codCliente;
    usuarioparams.codTpoPortal = this.environment.tipoPortal;
    usuarioparams.docEmpresa = this.environment.docEmpresa;
    usuarioparams.docUsuario = sessionStorage.getItem('docUsuario');

    this.crearCuentaService.doObtenerUsuario(usuarioparams).subscribe(data => {
      if (data.codret == '0') {
        this.editarForm.get('docusuario').setValue(data.usuario.docUsuario);
        this.editarForm.get('nombre').setValue(data.usuario.nomUsuario);
        this.editarForm.get('apellidopaterno').setValue(data.usuario.apePatUsuario);
        this.editarForm.get('apellidomaterno').setValue(data.usuario.apeMatUsuario);
        this.editarForm.get('codfijo').setValue(this.setCodTelefono(data.usuario.fonoUsuario));
        this.editarForm.get('fonofijo').setValue(this.setNumTelefono(data.usuario.fonoUsuario));
        this.editarForm.get('codmovil').setValue(this.setCodTelefono(data.usuario.movilUsuario));
        this.editarForm.get('fonomovil').setValue(this.setNumTelefono(data.usuario.movilUsuario));
        sessionStorage.setItem('movilUsuario', JSON.stringify(data.usuario.movilUsuario));
        this.editarForm.get('direccion').setValue(data.usuario.direccion);
        this.editarForm.get('emailusuario').setValue(data.usuario.emailUsuario);
      } else {
        console.error("Error al obtener usuario", data.descret);
        this.showDialogFail('Error', 'Usuario no encontrado.<br>Intente más tarde o contáctese con el administrador de sistema.', false);
      }
    }, error => {
      console.error("Error callObtenerUsuario", error);
      this.showDialogFail('Error', 'Servicio temporalmente no disponible.', false);
    });

  }

  cancelarEdicion() {
    this.router.navigate(['/home']);
  }

  getNotNullValue(formGroup: FormGroup, nameField: string): string {
    let resultado: string = formGroup.get(nameField).value;
    return (resultado == null) ? '' : resultado;
  }

  showDialog(pDialogName: string, pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true,
      minWidth: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      let respuesta: string = result.toString();
      console.log('The dialog was closed ' + respuesta);
      if (pDialogName == 'dialogExitoso') {
        if (respuesta == 'true') {
          this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
          this.userPerfil['usuario']['nomUsuario'] = this.editarForm.get('nombre').value;
          sessionStorage.setItem('userPerfil', JSON.stringify(this.userPerfil));
          this.router.navigate(['/home']);
        }
      }
    });
  }

  showDialogStop(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ' + result);
    });
  }
  
  showDialogFail(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ' + result);
      this.cancelarEdicion();
    });
  }

  formatearTelefono(event: any, campo: string) {
    let numero: string = event.target.value;
    if (numero == '' || numero == 'null') return;
    numero = numero.replace(/\s/g, "");
    let sep = ' ';

    let len = numero.length;
    let numeroFormato = numero;

    if (len <= 10) {
      numeroFormato = numero.replace(/(\d{1,3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3');
    }
    else if (len > 10) {
      numeroFormato = numero.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3' + sep + '$4');
    }
    if (campo == 'movil')
      this.editarForm.get('fonomovil').setValue(numeroFormato);
    else if (campo == 'fijo')
      this.editarForm.get('fonofijo').setValue(numeroFormato);

  }

  setearTelefono(numero: string) {
    if (numero == '' || numero == null) return numero;
    //console.log(numero);
    numero = numero.replace(/\s/g, "");
    //console.log(numero);
    let sep = ' ';

    let len = numero.length;
    let numeroFormato = numero;

    if (len <= 10) {
      numeroFormato = numero.replace(/(\d{1,3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3');
    }
    else if (len > 10) {
      numeroFormato = numero.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3' + sep + '$4');
    }
    return numeroFormato;
  }

}



