export class CrearCuentaDatosPeticion {
    codCliente: string;
    codTpoPortal: string;
    docEmpresa: string;

    nombre: string;
    paterno: string;
    materno: string;
    email: string;
    fonofijo: string;
    fonomovil: string;
    docUsrIde: string;
    docUsrEmp: string;
    direccion: string;
    claveUsuario: string;
    idUsuario: string; //idUsuario logueado para el caso del Adquirente y Comercio al crear un usuario.
}