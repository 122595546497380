import { Component, ElementRef, HostListener, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatStepper } from '@angular/material';
import { DialogBaseComponent } from '../dialogs/dialog-base/dialog-base.component';
import { formatDate, DatePipe } from '@angular/common';
import { CustomValidators } from 'src/app/util/custom-validators';
import { EmpresaResult } from 'src/app/models/mandatos-express/empresa-result';
import { ListPacParametroPeticion } from 'src/app/models/mandatos-express/listPacParametroPeticion';
import { InscribirMandatosModel } from 'src/app/models/mandatos-express/addMandato';
import { BuscarImagenBoleta } from 'src/app/models/mandatos-express/buscarImagenBoleta';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Ciudad } from 'src/app/models/mandatos-express/ciudad';
import { MandatosExpressService } from 'src/app/services/mandatos-express.service';
import { LoaderBaseService } from 'src/app/services/loader-base.service';
import { RubroRequest } from 'src/app/models/mandatos-express/rubro-request';
import { TerminoCondiciones } from 'src/app/models/mandatos-express/condiciones';
import { TerminoCondicionesFinal } from 'src/app/models/mandatos-express/condicionesFinal';
import { RubroResult } from 'src/app/models/mandatos-express/rubro-result';
import { EmpresasPeticion } from 'src/app/models/mandatos-express/empresasPeticion';
import { MediosPago } from 'src/app/models/mandatos-express/mediosPago';
import { InscribirMandatoResponse } from 'src/app/models/mandatos-express/addMandatoResponse';
import { Estilos } from 'src/app/services/estilos/estilos';
import { CardinalCommerceService } from 'src/app/services/cardinal-commerce/cardinal-commerce.service';
import { ValidationPanParams } from 'src/app/models/mandatos-express/validation-trx-params';
import { ValidationPanResponse } from 'src/app/models/mandatos-express/validation-trx-response';
import { CardinalTokenResponse } from 'src/app/models/mandatos-express/cardinal-token-response';
import { CondicionesGeneralesComponent } from '../condiciones-generales/condiciones-generales.component';
import { MatHorizontalStepper } from '@angular/material';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Embebido } from 'src/app/models/mandatos-express/apiEmbebidoPeticion';
import { ServiciosComercio } from 'src/app/models/mandatos-express/serviciosComercio';
import { ListPeticionEmbebido } from 'src/app/models/mandatos-express/listPeticionEmbebido';
import { ValidaSesionPeticion } from 'src/app/models/mandatos-express/validasesionpeticion';
import { SatDatepickerInput, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { isDate } from 'util';
import { FormatMonedaChilePipe } from 'src/app/util/currency.pipe';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';


declare function setupCardinalCommerce(): any;
declare function validatePayment(accountNumber: string, expirationMonth: string, expirationYear: string, cardCode: string, orderNumber: string, jWTContainer: string): any;

@Component({
  selector: 'app-add-mandatos-express',
  templateUrl: './add-mandatos-express.component.html',
  styleUrls: ['./add-mandatos-express.component.css'],
  providers: [MandatosExpressService, CardinalCommerceService, LoaderBaseService, DatePipe, AutenticadorService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }]
})
export class AddMandatosExpressComponent implements OnInit {

  lengthMontoPorMoneda: number = 11;

  firstFormGroup: FormGroup = new FormGroup({
    rutSuscriptor: new FormControl('', Validators.compose([CustomValidators.rutValidator, Validators.required])),
    nombreSuscriptor: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
    apellidoA: new FormControl('', Validators.compose([Validators.required, CustomValidators.nameValidator])),
    apellidoB: new FormControl('', CustomValidators.nameValidator),
    idRubro: new FormControl('', Validators.required),
    idEmpresa: new FormControl({ value: '', disabled: true }, Validators.required),
    diaPago: new FormControl('', Validators.required),
    idServicio: new FormControl({value:'', disabled: true}, Validators.compose([Validators.required, CustomValidators.idServiceValidator])),
    servicioAlias: new FormControl('', Validators.compose([Validators.required, CustomValidators.descriptionValidator])),
    medioPago: new FormControl('', Validators.compose([Validators.required, CustomValidators.cardNumberValidator, Validators.minLength(15), Validators.maxLength(19), CustomValidators.numberValidator])),
    mesMedioPago: new FormControl('', Validators.compose([Validators.required])),
    anioMedioPago: new FormControl('', Validators.required),
    codSeg: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(4)])),
    medioPagoMontoMax: new FormControl({ value: '0', disabled: true}, Validators.compose([Validators.maxLength(this.lengthMontoPorMoneda), CustomValidators.amountValidator])),
    montofijo: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(this.lengthMontoPorMoneda), CustomValidators.amountValidator, CustomValidators.greaterThanZero])),
    contactoEmail: new FormControl('', Validators.compose([Validators.required, CustomValidators.validaEmail])),
    notificacionEmail: new FormControl(false, Validators.required),
    codfijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2)])),
    contactoFijo: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.minLength(8)])),
    codmovil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.maxLength(2)])),
    contactoMovil: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.minLength(8)])),
    contactoDireccion: new FormControl('', CustomValidators.descriptionValidator),
    contactoCiudad: new FormControl(''),
    medioPagoAdquirente: new FormControl('', Validators.required),
    servicioComercio: new FormControl('', Validators.required),
    nombrePromotor: new FormControl(''),
    fechaInicioVigencia: new FormControl(''),
    fechaFinVigencia: new FormControl(''),
    cantidadDeCobros: new FormControl('', Validators.compose([CustomValidators.numberValidator, Validators.min(1), Validators.max(1000000)]))
  }, CustomValidators.validaMes);

  unavailabilityForm: FormGroup;

  confirmacionForm = new FormGroup({
    declaracion: new FormControl(false, Validators.required)
  });

  idFac: string = '';
  idSesion: string = '';
  ambiente: string;
  tipoCobro: string = 'V';
  urlSalida: string = '';
  cardBrand: string = '';
  imgtarjeta: string= '';
  orderNumber: string = '';
  jWTContainer: string = '';
  imagenBoleta: string;
  expCharByMoneda: string = '^[0-9]*$';
  fechaValidezFin: string = '01/01/2000';
  etiquetaPromotor: string = '';
  fechaValidezDesde: string = '01/01/2000';
  tipoUnidMonetaria: string = '';
  numeroFijoCompleto: string;
  numeroMovilCompleto: string;
  formatoImagenBoleta: string;
  unidadMonetariaSelec: string = 'CLP';
  placeHolderPromotor: string = '';
  rubroSelectedOptiontText: string = '';
  empresaSelectedOptiontText: string = '';
  ciudadSelectedOptiontText: string = '';

  radioButtonOption: number = 0;

  isLinear: boolean = false;
  esComercio: boolean = false;
  isEditable: boolean = true;
  isEmbebido: boolean = false;
  isCompleted: boolean = false;
  tieneImagen: boolean = false;
  nuevaImagen: boolean = false;
  esAdquirente: boolean = false;
  utilizaPromotor: boolean = false;

  datosApi: any;
  idUsuario: any;
  userPerfil: any;
  base64Image: SafeResourceUrl;
  resultadoListPacParametro: any;
  preLoginsLS: { codTpoPortal: string; };

  mandato = new InscribirMandatosModel();
  estilos : Estilos = new Estilos();
  sesionId: ListPeticionEmbebido = new ListPeticionEmbebido();
  dateNow: Date = new Date();
  embebido: Embebido = new Embebido();
  dialogRef: MatDialogRef<DialogBaseComponent, null>;
  respuesta: InscribirMandatoResponse = new InscribirMandatoResponse('', '', '');
  buscarImagen: BuscarImagenBoleta = new BuscarImagenBoleta();
  peticionEmpresa = new EmpresasPeticion();
  terminoCond : TerminoCondiciones = new TerminoCondiciones();
  peticionSesion: ValidaSesionPeticion = new ValidaSesionPeticion();
  terminoCondFinal : TerminoCondicionesFinal = new TerminoCondicionesFinal();
  formatCurrencyChile: FormatMonedaChilePipe = new FormatMonedaChilePipe();
  validatePanParams: ValidationPanParams = new ValidationPanParams();
  peticionListPacParametro = new ListPacParametroPeticion();
  
  servicios:Array<any> = [];
  arrayCiudades: Array<Ciudad> = [];
  resultadoRubros: RubroResult[] = [];
  resultadoEmpresa: EmpresaResult[] = [];
  resultadoMediosPago: MediosPago[] = [];
  resultadoCodUnidMonetaria: any[] = [{ option: 'CLP', value: '$' }];
  resultadoServiciosComercio: ServiciosComercio[] = [];
  arrayMeses: Array<{ codigo: string, valor: string }> = [];
  arrayAnios: Array<{ codigo: string, valor: number }> = [];

  @ViewChild(MatHorizontalStepper, { static: false }) myStepper: MatHorizontalStepper;
  showEspera: boolean = false;

  @ViewChild('countdown', null) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  @ViewChild('countdownInactivity', null) counterIncativity: CountdownComponent;
  countdownInactivityConfig: CountdownConfig = {};

  constructor(
    private mandatoservice: MandatosExpressService,
    private service: CardinalCommerceService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private loader: LoaderBaseService,
    public domSanitizer: DomSanitizer,
    private elementRef: ElementRef,
    public datepipe: DatePipe,
    @Inject('env') private environment: any,
    private autenticatorService: AutenticadorService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    const today = new Date();

    this.fechaValidezDesde = this.formatFecha(today);
    
    this.unavailabilityForm = this.formBuilder.group({
      minDate: today,
      minDateFin: today
    });
  }

  ngOnInit() {
    this.loader.hide();

    this.isEmbebido = false;

    this.utilizaPromotor = false;

    this.firstFormGroup.controls['medioPagoAdquirente'].disable();
    this.firstFormGroup.controls['servicioComercio'].disable();
    this.firstFormGroup.get('idServicio').setValue('');
    this.firstFormGroup.get('codSeg').setValue('');

    this.urlSalida = '';
    this.idSesion = '';
    this.activeRoute.queryParams.subscribe(params => {

      let url = '';
      if(params['url']){
        url = params['url'];
        const encode1 = decodeURIComponent(url);
        this.urlSalida = decodeURIComponent(encode1);
      }      

      if(params['sesion']){
        this.idSesion = params['sesion'];
        this.validaSesion();
        this.embebidoData();
        this.isEmbebido = true;
      }else{
        this.preLoginsWait();
      }
    });

    this.setListAnios();
    this.setListCiudades();
    this.setListMeses();
    this.initializeCountDown();
    
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (respuesta: any, secure3d: any) => this.responseCardinal(respuesta, secure3d), };
    
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetInactivity() {
    this.counterIncativity.restart();
  }

  handleEvent(event: CountdownEvent) {
    if (event.action == 'notify') {
      console.log("notify...");
    }
  }

  handleEventInactivity(event: CountdownEvent) {
    if (event.action == 'notify') {
      console.log("Usuario sin actividad...");
      this.showDialogInactividad();
    }
    if (event.action == 'done') {
      console.log("No hubo actividad del usuario");
      this.dialog.closeAll();
      this.redirigirUsuario();
    }
  }

  showDialogInactividad() {
    this.dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: 'Advertencia', content: 'Por inactividad se cerrará la sesión.', showBtnCancel: false, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed [' , result);
      if(result === true){
        console.log('Reenviar loginPAR')
        this.configParExpress();
      }
    });
  }

  validaSesion(){
    this.peticionSesion.token = this.idSesion;
    this.autenticatorService.postValidaSesion(this.peticionSesion).subscribe(data => {
      if('0' != data.codret){
        //TODO Arreglar
        //sessionStorage.setItem('preLoginError', JSON.stringify(data));
        //this.router.navigate(['/mantencion']);
      }
    });
  }

  embebidoData(){
    sessionStorage.setItem("date", Date());
    this.embebido.uuid = this.idSesion;
    sessionStorage.setItem('sesionIdSesion', this.embebido.uuid);

    this.autenticatorService.postApiLoginEmb(this.embebido).subscribe(data => {
      if(data.codret != '0'){
        sessionStorage.setItem('preLoginError', JSON.stringify(data));
        this.router.navigate(['/mantencion']);
      }else{
        if (data.estadoPortal == 'SUSPENDIDO') {
          sessionStorage.setItem('preLoginError', JSON.stringify(data));
          this.router.navigate(['/mantencion']);
        }else{
          sessionStorage.setItem('preLogin', JSON.stringify(data));
          sessionStorage.setItem('ambiente',data['codTpoPortal']); 
          sessionStorage.setItem('userPerfil', JSON.stringify(data));
 
          sessionStorage.setItem('docUsuario', data['usuario']['emailUsuario'].toString());
          sessionStorage.setItem('idPerfil', data['perfil']['idPerfil'].toString());
          sessionStorage.setItem('currentUser', JSON.stringify(data['usuario']));
          sessionStorage.setItem('tokenSession', data['tokenSession']);
          sessionStorage.setItem('minExpSession', data['perfil']['polMinExpSesion'].toString());

          this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
          this.setDatosUsuarioDefault();
          this.postApiDataComercio();
          this.postSentRubrosServices();
          this.estilos.cambioEstilos(this.elementRef);
          this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
          this.idUsuario = this.userPerfil.usuario.idUsuario;
        } 
      }
   }, error => {
      console.log(error);
      sessionStorage.setItem('preLoginError', JSON.stringify(error));
      this.router.navigate(['/mantencion']);
   });
  }

  setDatosUsuarioDefault(){
    this.firstFormGroup.get('nombreSuscriptor').setValue(this.userPerfil.usuario.nomUsuario);
    this.firstFormGroup.get('apellidoA').setValue(this.userPerfil.usuario.apePatUsuario);
    this.firstFormGroup.get('apellidoB').setValue(this.userPerfil.usuario.apeMatUsuario);
    this.firstFormGroup.get('contactoEmail').setValue(this.userPerfil.usuario.emailUsuario);

    if (this.userPerfil.usuario.fonoUsuario != null) {
      let fonoUsuario: string = this.userPerfil.usuario.fonoUsuario;
      this.firstFormGroup.get('codfijo').setValue(fonoUsuario.substring(0, 2));
      this.firstFormGroup.get('contactoFijo').setValue(fonoUsuario.substring(2, fonoUsuario.length));
    }
    if (this.userPerfil.usuario.movilUsuario != null) {
      let movilUsuario: string = this.userPerfil.usuario.movilUsuario;
      this.firstFormGroup.get('codmovil').setValue(movilUsuario.substring(0, 2));
      this.firstFormGroup.get('contactoMovil').setValue(movilUsuario.substring(2, movilUsuario.length));
    }
    if (this.userPerfil.usuario.direccion != null) {
      this.firstFormGroup.get('contactoDireccion').setValue(this.userPerfil.usuario.direccion);
    }
  }

  postApiDataComercio() {
    this.firstFormGroup.controls['servicioComercio'].enable();

    this.firstFormGroup.controls['medioPagoAdquirente'].disable();
    this.firstFormGroup.controls['idRubro'].disable();
    this.sesionId.sesion_id_ses = this.idSesion;

    this.mandatoservice.postDatosEmbebido(this.sesionId).subscribe(data => {
      this.esComercio = true;

      this.firstFormGroup.get('rutSuscriptor').setValue(data['doc_id_usr_emp']);
      this.firstFormGroup.get('nombreSuscriptor').setValue(data['nom_usr_emp']);
      this.firstFormGroup.get('apellidoA').setValue(data['pat_usr_emp']);
      this.firstFormGroup.get('apellidoB').setValue(data['mat_usr_emp']);

      this.firstFormGroup.get('contactoEmail').setValue(data['eml_usr_emp']);

      this.firstFormGroup.get('codfijo').setValue(this.setCodTelefono(data['fno_usr_emp']));
      this.firstFormGroup.get('contactoFijo').setValue(this.setNumTelefono(data['fno_usr_emp']));
      this.firstFormGroup.get('codmovil').setValue(this.setCodTelefono(data['mvl_usr_emp']));
      this.firstFormGroup.get('contactoMovil').setValue(this.setNumTelefono(data['mvl_usr_emp']));
      this.firstFormGroup.get('contactoDireccion').setValue(data['dir_usr_emp']);

      this.resultadoServiciosComercio = data['servicios'];

      for(let i=0; i < data['servicios'].length; i++){
        let ids_default = data['servicios'][i]['ids_default'];
        this.servicios.push({name: data['servicios'][i]['ids_usr_ids'], value: data['servicios'][i]['dsc_usr_ids']})
        if(ids_default == 'D'){
          this.firstFormGroup.get('servicioAlias').setValue(data['servicios'][i]['dsc_usr_ids']);
          this.firstFormGroup.get('servicioComercio').setValue(data['servicios'][i]['ids_usr_ids']);
          this.firstFormGroup.get('idServicio').setValue(data['servicios'][i]['ids_usr_ids']);
        }
        
        if(i == 0){
          let idFacturador = data['servicios'][i]['id_facturador'];
          this.idFac = idFacturador;
          this.peticionListPacParametro.idFacturador = Number(idFacturador);
          this.postPacParametroListarServices(this.peticionListPacParametro);
          let mtoMax = data['servicios'][i]['ids_mto_max'];
          this.firstFormGroup.get('medioPagoMontoMax').setValue(mtoMax);

          let rbrCod = data['servicios'][i]['rbr_cod'];

          this.firstFormGroup.get('idRubro').setValue(rbrCod);
        
          this.peticionEmpresa.codRubro = rbrCod;
          this.peticionEmpresa.codCliente = this.environment.codCliente;
          this.peticionEmpresa.codEstadoConvenio = 'HBL';
          this.postSentEmpresaServices(this.peticionEmpresa);
          if(this.tipoCobro!='F'){
            this.firstFormGroup.get('diaPago').setValue('0');
          }
          this.firstFormGroup.get('idEmpresa').setValue(Number(idFacturador));

          this.rubroSelectedOptiontText = data['servicios'][i]['rbr_desc'];
          this.empresaSelectedOptiontText = data['servicios'][i]['raz_soc_emp'];

        }
          
      }
    });
  }

  onServicioSelect(event:any){
    this.firstFormGroup.get('idServicio').setValue(event.value);
    this.firstFormGroup.controls['medioPagoAdquirente'].disable();

    for(let x = 0; x < this.servicios.length; x++){
      if(this.servicios[x].name == event.value){
        this.firstFormGroup.get('servicioAlias').setValue(this.servicios[x].value);
      }
    }
  }

  onTarjetaSelect(event:any){
    let fecha = this.resultadoMediosPago.find(x => x.pan_usr_medpag == event.value).fec_exp_usr_medpag;
    let mes = fecha.substring(0,2);
    let anho = fecha.substring(2,4);
    
    this.firstFormGroup.get('medioPago').setValue(event.value);
    this.firstFormGroup.get('mesMedioPago').setValue(mes);
    this.firstFormGroup.get('anioMedioPago').setValue(anho);

    this.getCardBrand(event.value);
  }

  getCardBrand(cardPan: string) {
    if (this.firstFormGroup.get('medioPago').valid) {
      let bin8digits = cardPan.substring(0, 8);
      // let params: ConsultaBinParams = new ConsultaBinParams();
      // params.bin = bin8digits;
      // this.loader.show();
      this.cardBrand = 'UNKNOW';

      let bin6digits = bin8digits.substring(0, 6);
      let bin2digits = bin8digits.substring(0, 2);
      let bin1digits = bin8digits.substring(0, 1);

      if (cardPan.length == 15 && (Number(bin2digits) == 34 || Number(bin2digits) == 37)) {
          //AMEX
          this.imgtarjeta = "amex";
          this.cardBrand = 'AMEX';
      } else if (cardPan.length == 16 && ((Number(bin6digits) >= 510000 && Number(bin6digits) <= 559999) || (Number(bin6digits) >= 222100 && Number(bin6digits) <= 272099))) {
          //MASTER
          this.imgtarjeta = "mastercard";
          this.cardBrand = 'MASTERCARD';
      } else if (cardPan.length <= 19 && ((Number(bin2digits) == 50) || (Number(bin2digits) >= 56 && Number(bin2digits) <= 64) || (Number(bin2digits) >= 66 && Number(bin2digits) <= 69))) {
          //MAESTRO
          this.imgtarjeta = "maestro";
          this.cardBrand = 'MAESTRO';
      } else if (cardPan.length <= 19 && (Number(bin1digits) == 4)) {
          //VISA
          this.imgtarjeta = "visa";
          this.cardBrand = 'VISA';
      } else {
          this.imgtarjeta = "unknow";
          this.cardBrand = 'UNKNOW';
      }
    }
  }

  validarSubscripcion() {
    if(this.confirmacionForm.valid) {

      console.log("Tarjeta " + this.cardBrand + " " + this.cardBrand == 'VISA' || this.cardBrand == 'MASTERCARD')
      if(this.cardBrand == 'VISA' || this.cardBrand == 'MASTERCARD'){        
        console.log("Sin validacion 50 pesos")
        this.validaFirstForm();
      }else{
        this.showDialog('Información:', `Con el fin de autenticar la inscripci&oacute;n de tu <br> PAR, realizaremos un cargo de $50 en tu <br> tarjeta, el cual ser&aacute; inmediatamente <br> anulado. ¿Autorizas esta transacci&oacute;n?`, true, 'No Acepto', 'Si, Acepto' );
      }
    } else {
      this.showDialog('Error', 'Debe aceptar las condiciones de uso antes de continuar.', false, 'Cancelar', 'Aceptar');
    }
  }

  responseCardinal(respuesta: string, secure3d: any) {
    //Recibe la respuesta de cardinal commerce
    console.log('Respuesta Cardinal: ', respuesta, secure3d);
    switch (respuesta) {
      case "SUCCESS":
        let secure3DGenerated = this.generateSecure3D(this.cardBrand, secure3d);
        this.validacionTrx(secure3DGenerated);
        break;

      case "NOACTION":
        this.validacionTrx('');
        break;

      case "FAILURE":
        this.loader.hide();
        this.showDialog('Error', 'Error al validar la segunda clave, intente nuevamente.', false, 'Cancelar', 'Aceptar');
        break;

      case "ERROR":
        this.validacionTrx('');
        break;

      default :
        this.validacionTrx('');
        break;
    }

  }

  generateSecure3D(cardBrand:string, secure3d:any){
    let eciflag = secure3d.Payment.ExtendedData.ECIFlag;
    let version = '0' + secure3d.Payment.ExtendedData.ThreeDSVersion.substring(0,1);
    let cavv = secure3d.Payment.ExtendedData.CAVV + '                                        '; //40
    let dsTrxId = secure3d.Payment.ExtendedData.DSTransactionId + '                                        ';//40
    let ucafIndicator = secure3d.Payment.ExtendedData.UCAFIndicator;

    if(cardBrand == 'VISA' || cardBrand == 'AMEX'){
      return eciflag + version + cavv.substring(0,40) + dsTrxId.substring(0,40);
    } else if(cardBrand == 'MASTERCARD' || cardBrand == 'MAESTRO'){
      return eciflag + version + cavv.substring(0,32) + dsTrxId.substring(0,36) + ucafIndicator;
    } else {
      return 'UNKNOW';
    }
  }

  getCodFacturador(idFacturador:number){
    for (let index = 0; index < this.resultadoEmpresa.length; index++) {
      const empresa = this.resultadoEmpresa[index];
      if(empresa.idFacturador == idFacturador){
        return empresa.codFacturador;
      }
    }
  }

  validacionTrx(secure3d: string) {
    this.validatePanParams.pan = this.firstFormGroup.get('medioPago').value;
    this.validatePanParams.fecexp = this.firstFormGroup.get('mesMedioPago').value + this.firstFormGroup.get('anioMedioPago').value;
    this.validatePanParams.cvv = (this.firstFormGroup.get('codSeg').value != '' ? this.firstFormGroup.get('codSeg').value : '000');
    this.validatePanParams.secure3d = secure3d;
    this.validatePanParams.cuotas = '0';
    this.validatePanParams.idcomercio = this.getCodFacturador(this.firstFormGroup.get('idEmpresa').value);
    this.validatePanParams.trxmoneda = 'CLP';
    this.validatePanParams.trxmonto = '5000';

    this.service.validacionPan(this.validatePanParams, this.isEmbebido).subscribe((data: ValidationPanResponse) => {
      if (data.codigo == '00') {
        console.log('Validacion exitosa, se guarda el mandato...');
        this.saveMandato();
      } else {
        console.error('Error: ' + data.codigo + " | " + data.descripcion);
        this.showDialog('Error', 'Error al realizar transacci&oacute;n de validaci&oacute;n, intente nuevamente.', false, 'Cancelar', 'Aceptar');
        this.loader.hide();
      }
    }, err => {
      
      console.log('Error al llamar a servicio que valida Trx: ' + err.message);
      this.showDialog('Error', 'Error al realizar transacci&oacute;n de validaci&oacute;n, comun&iacute;­quese con el administrador.', false, 'Cancelar', 'Aceptar');
      this.loader.hide();
    });
  }

  validaFirstForm() {
    this.loader.show();

    let codCliente:string = this.environment.codCliente;
    let docEmpresa:string = this.resultadoEmpresa.find(x => x.idFacturador == Number.parseInt(this.firstFormGroup.get('idEmpresa').value)).docFacturador;

    //Se valida e inicializan los valores para validacion de cardinal commerce
    this.service.getTokenCardinal(codCliente, docEmpresa, '50', '152', this.isEmbebido).subscribe((data: CardinalTokenResponse) => {
      if(data.codret == '0'){
        this.jWTContainer = data.token;
        this.orderNumber = data.orderNumber;
  
        setupCardinalCommerce();
  
        validatePayment(
          this.firstFormGroup.get('medioPago').value,
          this.firstFormGroup.get('mesMedioPago').value,
          this.firstFormGroup.get('anioMedioPago').value,
          (this.firstFormGroup.get('codSeg').value != '' ? this.firstFormGroup.get('codSeg').value : '000'),
          this.orderNumber,
          this.jWTContainer
        );
      } else if(data.codret == '1'){
        //El comercio no tiene credenciales para cardinal commerce
        console.log('Comercio no tiene credenciales para 3DS');
        this.validacionTrx('');
      } else {
        console.log('Error al llamar a servicio que genera token: ' + data.descret);
        this.loader.hide();
        this.showDialog('Error', 'Error al llamar a servicio que genera token de validaci&oacute;n, intente nuevamente.', false, 'Cancelar', 'Aceptar');
      }
    }, err => {
      console.log('Error al llamar a servicio que genera token: ' + err.message);
      this.loader.hide();
      this.showDialog('Error', 'Error al llamar a servicio que genera token de validaci&oacute;n, intente nuevamente.', false, 'Cancelar', 'Aceptar');
  });
 
  }

  validaMonto(str: string) {
    if(this.unidadMonetariaSelec === 'UF' || this.unidadMonetariaSelec === 'UTM'){
      if (str) {
        str = this.formatCurrencyChile.transform(str, this.unidadMonetariaSelec);
        
        let parteEntera, decimales = "";
        if(str.includes(",")){
          const [, parteEnteraAr, decimalesAr] = str.replace(/\./g, '').match(/(\d+),(\d+)$/) || [null, '', ''];
          parteEntera = parteEnteraAr;
          decimales = decimalesAr;
        }else{
          const [, parteEnteraAr] = str.replace(/\./g, '').match(/(\d+)$/) || [null, '', ''];
          parteEntera = parteEnteraAr;
        }

        if (decimales.length < 6) {
          const cerosFaltantes = 6 - decimales.length;
          const decimalesRellenos = decimales + '0'.repeat(cerosFaltantes);
          const numeroFinal = Number.parseInt(parteEntera + decimalesRellenos).toString();
          return numeroFinal.replace(/\./g, '');
        }
        return str.replace(/\./g, '').replace(/,/g, '');
      }
    } else {
      if (str) {
        return (parseInt(str.replace(/[^0-9]/g, '')) * 1_000_000).toString();
      }
    }
    return str;
  }

  validFormulario() {
    if(this.firstFormGroup.valid) {
      
      if(!this.utilizaPromotor){
        this.firstFormGroup.get('nombrePromotor').setValue('');
      }

      this.terminoCondicionesParam();
      this.myStepper.next();
    }else{
      this.markInvalidFileds();
    }
  }

  markInvalidFileds(){
    for(const filed in this.firstFormGroup.controls){
      const control=this.firstFormGroup.get(filed);
      if(control.invalid){
        console.log(`El campo "${filed}" es inválido. Errores: `, control.errors);
      }
    }
  }

  terminoCondicionesParam()  {
    this.terminoCond.idEmpresa = ""+this.firstFormGroup.get('idEmpresa').value;
    this.terminoCond.rut =this.firstFormGroup.get('rutSuscriptor').value;
    this.terminoCond.nombre = this.firstFormGroup.get('nombreSuscriptor').value;
    this.terminoCond.primerApellido =  this.firstFormGroup.get('apellidoA').value;
    this.terminoCond.segundoApellido =  this.firstFormGroup.get('apellidoB').value;
    this.terminoCond.tarjeta = this.firstFormGroup.get('medioPago').value;
    this.terminoCond.tarjetaTipo = this.imgtarjeta.toUpperCase();
    this.terminoCond.fechaVctoTarjeta = this.firstFormGroup.get('mesMedioPago').value+"/"+this.firstFormGroup.get('anioMedioPago').value;
    let fechaHoy = new Date();
    this.terminoCond.fechaSubscripcion = this.datepipe.transform(fechaHoy, 'dd/MM/yyyy');
    this.terminoCond.idServicio = this.firstFormGroup.get('idServicio').value;
  }

  saveMandato() {
    this.mandato.idRecaudador = '-1';
    this.mandato.idEmpresa = this.firstFormGroup.get('idEmpresa').value;
    this.mandato.rutSuscriptor = this.firstFormGroup.get('rutSuscriptor').value;
    this.mandato.nombreSuscriptor = this.firstFormGroup.get('nombreSuscriptor').value;
    this.mandato.apellido1Suscriptor = this.firstFormGroup.get('apellidoA').value;
    this.mandato.apellido2Suscriptor = this.firstFormGroup.get('apellidoB').value;
    this.mandato.idServicioEmpresa = this.firstFormGroup.get('idRubro').value;
    this.mandato.idFacturador = this.firstFormGroup.get('idEmpresa').value;
    this.mandato.idServicio = this.firstFormGroup.get('idServicio').value;
    this.mandato.servicioAlias = this.firstFormGroup.get('servicioAlias').value;

    this.mandato.medioPago = this.firstFormGroup.get('medioPago').value;
    this.mandato.tipoMedioPago = this.cardBrand;

    this.mandato.mesMedioPago = this.firstFormGroup.get('mesMedioPago').value;
    this.mandato.anioMedioPago = this.firstFormGroup.get('anioMedioPago').value;

    if (this.tipoCobro == 'V') {
      this.mandato.medioPagoMontoMax = this.validaMonto(this.firstFormGroup.get('medioPagoMontoMax').value);
      this.firstFormGroup.get('montofijo').disable();
      this.mandato.montofijo = '0';
      this.mandato.diaPago = '0';
    } else {
      this.mandato.montofijo = this.validaMonto(this.firstFormGroup.get('montofijo').value);
      this.firstFormGroup.get('medioPagoMontoMax').disable();
      this.mandato.diaPago = this.firstFormGroup.get('diaPago').value;
    }

    this.mandato.contactoEmail = this.firstFormGroup.get('contactoEmail').value;

    let codFijo:string = this.firstFormGroup.get('codfijo').value;
    let fonofijo:string = this.firstFormGroup.get('contactoFijo').value;
    this.numeroFijoCompleto = codFijo + fonofijo;
    this.mandato.contactoFijo = this.numeroFijoCompleto;
    let codmovil:string = this.firstFormGroup.get('codmovil').value;
    let fonomovil:string = this.firstFormGroup.get('contactoMovil').value;
    this.numeroMovilCompleto = codmovil + fonomovil;
    this.mandato.contactoMovil = this.numeroMovilCompleto;
    this.mandato.contactoDireccion = this.firstFormGroup.get('contactoDireccion').value;
    this.mandato.contactoCiudad = this.firstFormGroup.get('contactoCiudad').value;
    this.mandato.enviaMail = this.firstFormGroup.get('notificacionEmail').value ? "1" : "0";
    this.mandato.contactoNotificacion = "1";
    this.mandato.canal = 'express';
    this.mandato.idMandante = this.idUsuario;
    this.mandato.codCliente = this.environment.codCliente;
    this.mandato.docEmpresa = this.environment.docEmpresa;
    this.mandato.codTpoPortal = this.preLoginsLS.codTpoPortal;
    this.mandato.nombrePromotor = this.firstFormGroup.get('nombrePromotor').value;

    this.mandato.tipoRecurrencia = this.radioButtonOption;
    this.mandato.fechaInicioRecurrencia = this.fechaValidezDesde;
    this.mandato.fechaFinRecurrencia = (this.radioButtonOption === 1 ? this.fechaValidezFin : "");
    this.mandato.cantidadRecurrencia = (this.radioButtonOption === 2 ? this.firstFormGroup.get('cantidadDeCobros').value : 0);
    this.mandato.codUnidadMonetaria = this.unidadMonetariaSelec !== '' ? this.unidadMonetariaSelec : 'CLP';

    this.mandatoservice.postAddMandato(this.mandato, this.isEmbebido).subscribe(result => {
      let codret: number = Number(result.codret);
      switch (codret) {
        case 0:
          console.log('Se guardo mandato con exito', this.myStepper.selected.label);
          this.isEditable = false;
          this.isCompleted = true;
          this.myStepper.selected.completed = true;
          this.myStepper.next();
          this.terminoCondFinal.idMandato = result.idMandato;
          this.terminoCondFinal.codTpoPortal = this.preLoginsLS.codTpoPortal;
          this.terminoCondFinal.idUsuario = ""+this.idUsuario;
          this.terminoCondFinal.empresa = this.environment.docEmpresa;
          break;
        case -3:
          this.showDialog('Error', 'Ya existe una cuenta suscrita para el id de servicio.', false, 'Cancelar', 'Aceptar');
          console.log("Error al insertar mandato: " + result.descret);
        default:
          this.showDialog('Error', 'Ocurri&oacute; un error al suscribir mandato, intente nuevamente.', false, 'Cancelar', 'Aceptar');
          console.log('Error al insertar mandato: ' + result.descret);
      }
    }, error => {
      console.log('Error al llamar servicio addMandato: ' + error.toString());
      this.showDialog('Error', 'Ocurri&oacute; un error al suscribir mandato, comun&iacute;­quese con el administrador.', false, 'Cancelar', 'Aceptar');
    }
    )
    this.loader.hide();
  }


  postSentRubrosServices() {
    let params: RubroRequest = new RubroRequest();
    params.codEstadoConvenio = 'HBL';
    this.mandatoservice.postListaRubros(params, this.isEmbebido).subscribe(data => {
      if (data.codret == '0') {
        this.resultadoRubros = data.resultado;
      } else {
        console.error('Error en  postSentRubrosServices', data.descret);
      }
    }, err => {
      console.error('Error en  postSentRubrosServices', err);
    });
  }

  postSentEmpresaServices(body: EmpresasPeticion) {
    this.mandatoservice.postListaEmpresas(body, this.isEmbebido).subscribe(data => {
      if (data.codret == '0') {
        this.resultadoEmpresa = data.resultado;
        if(this.idFac != ''){
          this.buscarImagenes(this.idFac);
        }
      } else {
        console.log('Error en postSentEmpresaServices ', data.descret);
      }
    }, err => {
      console.error('Error en postSentEmpresaServices ', err);
    });
  }

  onRubroSelect(event: any) {
    this.firstFormGroup.controls['idEmpresa'].reset();
    this.firstFormGroup.controls['idServicio'].reset();

    this.peticionEmpresa.codRubro = event.value;
    this.peticionEmpresa.codCliente = this.environment.codCliente;
    this.peticionEmpresa.codEstadoConvenio = 'HBL';
    this.postSentEmpresaServices(this.peticionEmpresa);

    this.firstFormGroup.controls['idEmpresa'].enable();
    //this.firstFormGroup.controls['diaPago'].reset();
    this.tipoCobro = 'V';

    this.rubroSelectedOptiontText = event.source.selected._element.nativeElement.innerText.trim();

    this.unidadMonetariaSelec = 'CLP';
    this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }];
    this.expCharByMoneda = '^[0-9]*$';

    this.firstFormGroup.get('montofijo').setValue('');
    this.firstFormGroup.get('medioPagoMontoMax').setValue('0');
  }

  postPacParametroListarServices(body: ListPacParametroPeticion) {
    this.mandatoservice.postPacParametroListar(body, this.isEmbebido).subscribe(data => {
      let bandTipoConvUndMon = false;
      if (data.codret == '0') {
        for (let index = 0; index < data.resultado.length; index++) {
          if (data.resultado[index].codigoParametro == 'DIAS_CARGO') {
            if (data.resultado[index].valorParametro != '') {
              this.resultadoListPacParametro = [];
              let valorParametroString = data.resultado[index].valorParametro;
              var valorParametroLista = valorParametroString.split(",");
              for (let index = 0; index < valorParametroLista.length; index++) {
                this.resultadoListPacParametro.push(valorParametroLista[index]);
              }
            }
          }
          if (data.resultado[index].codigoParametro == 'TIPO_COBRO') {
            if (data.resultado[index].valorParametro == 'F') {
              this.tipoCobro = 'F';
              this.firstFormGroup.controls['diaPago'].enable();
              this.firstFormGroup.get('montofijo').enable();
              this.firstFormGroup.get('medioPagoMontoMax').disable();
            } else {
              this.tipoCobro = 'V';
              this.firstFormGroup.get('medioPagoMontoMax').enable();
              this.firstFormGroup.get('montofijo').disable();
            }

            if(this.tipoCobro == 'V'){
              this.firstFormGroup.get('diaPago').setValue('0');
            }else{
              this.firstFormGroup.get('diaPago').reset();
            }
          }

          if(data.resultado[index].codigoParametro == 'ETIQUETA_PROMOTOR'){
            this.utilizaPromotor = data.resultado[index].valorParametro != "";
            this.etiquetaPromotor = data.resultado[index].valorParametro;
            this.placeHolderPromotor = "Nombre de " + this.etiquetaPromotor;
          }

          if(data.resultado[index].codigoParametro == 'TIPO_CONVERSION_UNID_MON'){
            this.tipoUnidMonetaria = data.resultado[index].valorParametro != '' ?  data.resultado[index].valorParametro : 'CLP';
            //console.log('this.tipoUnidMonetaria '+ this.tipoUnidMonetaria);
            bandTipoConvUndMon = true;
            this.getUnidMonetaria();
          }
        }
      } else {
        console.log('Ocurri&oacute; un error al listar los par&aacute;metros del convenio para la empresa selecionada. ', data.descret);
      }
      if(!bandTipoConvUndMon){
        this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }];
      }
    }, err => {
      this.showDialog('Error', 'Ocurri&oacute; un error al obtener la informaci&oacute;n de la empresa selecionada.', false, 'Cancelar', 'Aceptar');
      console.log('Ocurri&oacute; un error al listar los par&aacute;metros del convenio para la empresa selecionada. ', err);
    });
  }

  getUnidMonetaria(): void {
    if (this.tipoUnidMonetaria != 'Mixto'){
      if(this.tipoUnidMonetaria === "UTM"){
        this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }, { option: 'UTM', value: 'UTM' }];
      }else if(this.tipoUnidMonetaria === "UF"){
        this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }, { option: 'UF', value: 'UF' }];
      }else if(this.tipoUnidMonetaria === "CLP"){
        this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }];
      }
    }else{
      this.resultadoCodUnidMonetaria = [{ option: 'CLP', value: '$' }, { option: 'UF', value: 'UF' }, { option: 'UTM', value: 'UTM' }];
    }

    const moneda = this.resultadoCodUnidMonetaria.find(obj => obj.option === this.unidadMonetariaSelec);
    
    if (moneda) {
      console.log('Esta la moneda en la lista')
    }else{
      console.log('No esta la moneda en la lista')
      this.unidadMonetariaSelec = 'CLP';
      this.expCharByMoneda = '^[0-9]*$';
    }

  }

  onEmpresaSelect(event: any) {
    this.firstFormGroup.controls['diaPago'].disable();
    this.peticionListPacParametro.idFacturador = event.value;
    this.utilizaPromotor = false;
    this.postPacParametroListarServices(this.peticionListPacParametro);

    this.buscarImagenes(event.value);

    this.empresaSelectedOptiontText = event.source.selected._element.nativeElement.innerText.trim();
    this.firstFormGroup.controls['idServicio'].enable();

    this.firstFormGroup.get('montofijo').setValue('');
    this.firstFormGroup.get('medioPagoMontoMax').setValue('0');

    this.unidadMonetariaSelec = 'CLP';
    this.expCharByMoneda = '^[0-9]*$';
  }

  onCiudadSelect(event: any) {
    this.ciudadSelectedOptiontText = event.source.selected._element.nativeElement.innerText.trim();
  }

  /*
  myKeyup(event: any) {
    this.firstFormGroup.controls['medioPagoMontoMax'].setValue(event.target.value);
  }
  myKeyupFijo(event: any) {
    this.firstFormGroup.controls['montofijo'].setValue(event.target.value);
  }*/

  setListAnios() {
    let hoy = new Date();
    for (let i = 0; i < 20; i++) {
      let anio = (hoy.getFullYear() + i).toString();
      let anioValue = Number.parseInt((anio + "").substring(2, 4));
      this.arrayAnios.push({ codigo: anio, valor: anioValue });
    }
  }

  setListCiudades() {
    this.mandatoservice.listCiudadesReport().subscribe(data => {
      this.arrayCiudades = data.resultado;
    });
  }

  setListMeses() {
    for (let i = 1; i <= 12; i++) {
      let mes = i.toString();
      this.arrayMeses.push({ codigo: (mes.length == 1 ? '0' + mes : mes), valor: (mes.length == 1 ? '0' + mes : mes) });
    }
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean, pBtnCancelar: string, pBtnAceptar: string ) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: pBtnCancelar, btnAceptar: pBtnAceptar },
      disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if( pBtnCancelar == 'No Acepto' && 'false' == result.toString() ){
        //No Acepto
        const dialogRefReafirmatorio = this.dialog.open(DialogBaseComponent, {
            data: { title: pTitle, content: `Estimado Suscriptor: No es posible <br> continuar con el proceso de registro de tu <br> PAR. ¿Deseas continuar o salir del proceso de <br> suscripción de tu PAR?`, showBtnCancel: pShowBtnCancel, btnCancelar: 'Salir', btnAceptar: 'Continuar' },
            disableClose: true
          });
  
          dialogRefReafirmatorio.afterClosed().subscribe(result => {      
            if( 'true' == result.toString() ){
            //Continuar
            this.showDialog(pTitle, pContent, pShowBtnCancel, pBtnCancelar, pBtnAceptar);
            }
            this.loader.hide();
          });
      }
      if( pBtnAceptar == 'Si, Acepto' && 'true' == result.toString() ){
        //Si, Acepto
        this.validaFirstForm();
      }
    });
  }
  
  showCondicionesGenerales() {
    const dialogRef = this.dialog.open(CondicionesGeneralesComponent, {
      data: { terminoCondiciones : this.terminoCond , terminoCondicionesFinal : this.terminoCondFinal, isEmbebido: this.isEmbebido},
      disableClose: true,
      maxHeight: '550px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log('Condiciones generales was closed ' + result.toString());
      }else{
        console.log('Condiciones generales was closed');
      }
      
    });
  }

  formatearTelefono(event: any, campo: string) {
    let numero = event.target.value;
    if(numero == '' || numero == 'null') return;
    numero = numero.trim();
    let sep = ' ';

    let len = numero.length;
    let numeroFormato = numero;

    if (len <= 10) {
      numeroFormato = numero.replace(/(\d{1,3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3');
    }
    else if(len >10){
      numeroFormato = numero.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, '$1' + sep + '$2' + sep + '$3' + sep + '$4');
    }
    if (campo == 'movil')
      this.firstFormGroup.get('contactoMovil').setValue(numeroFormato);
    else if (campo == 'fijo')
      this.firstFormGroup.get('contactoFijo').setValue(numeroFormato);

  }

  setCodTelefono(numero:string){
    let codigo:string = '';
    if(numero != null){
      if(numero.length != 0)
        codigo = (numero == 'null') ? '' : numero.substring(0,2);
    }
    return codigo;
  }

  setNumTelefono(numero:string){
    let numeroaparte:string = ''; 
    if(numero != null){
      if(numero.length != 0)
        numeroaparte = (numero == 'null') ? '' : numero.substring(2);
    }
    return numeroaparte;
  }

  ngOnDestroy() {
    sessionStorage.removeItem('componenteHijo');
    //this.autenticatorService.callPreLogin(false);
    sessionStorage.removeItem('tokenSessionParExpress');
  }

  buscarImagenes(idFact:string){

    let docFacturador:string =  this.resultadoEmpresa.find(x => x.idFacturador == Number.parseInt(idFact)).docFacturador;
    let idConvenio:string =  this.resultadoEmpresa.find(x => x.idFacturador == Number.parseInt(idFact)).idConvenio;

    this.buscarImagen.imgTpo="boleta";
    this.buscarImagen.cnv=idConvenio;
    this.buscarImagen.emp=docFacturador;
    this.buscarImagen.clt=this.environment.codCliente;
    this.mandatoservice.postBuscarImagenBoleta(this.buscarImagen, this.isEmbebido).subscribe(data => {
      if (data.codret == '0') {
        this.tieneImagen = true;
        this.imagenBoleta = data.img64;
        this.formatoImagenBoleta = data.imgFormat;
        this.base64Image = this.domSanitizer.bypassSecurityTrustResourceUrl('data:'+this.formatoImagenBoleta+';base64,' + this.imagenBoleta);
      }else{
        this.tieneImagen = false;
      }
    }, err => {
      console.log("error " + err);
    });
  }

  onFijoSelect(){
    this.firstFormGroup.get('codfijo').updateValueAndValidity();
    this.firstFormGroup.get('contactoFijo').updateValueAndValidity();

    let codfijo: string = this.firstFormGroup.get('codfijo').value;
    let contactoFijo: string = this.firstFormGroup.get('contactoFijo').value;
    if (codfijo.length > 0 && contactoFijo.length == 0) {
      this.firstFormGroup.get('contactoFijo').setErrors({ 'required': true });
      this.firstFormGroup.get('contactoFijo').markAsTouched();
    } else {
      if (codfijo.length == 0 && contactoFijo.length > 0) {
        this.firstFormGroup.get('codfijo').setErrors({ 'required': true });
        this.firstFormGroup.get('codfijo').markAsTouched();
      }
    }
  }
  onMovilSelect(){
    this.firstFormGroup.get('codmovil').updateValueAndValidity();
    this.firstFormGroup.get('contactoMovil').updateValueAndValidity();

    let codmovil: string = this.firstFormGroup.get('codmovil').value;
    let fonomovil: string = this.firstFormGroup.get('contactoMovil').value;
    if (codmovil.length > 0 && fonomovil.length == 0) {
      this.firstFormGroup.get('contactoMovil').markAsTouched();
      this.firstFormGroup.get('contactoMovil').setErrors({ 'required': true });
    } else {
      if (codmovil.length == 0 && fonomovil.length > 0) {
        this.firstFormGroup.get('codmovil').markAsTouched();
        this.firstFormGroup.get('codmovil').setErrors({ 'required': true });
      }
    }
  }

  preLoginsWait(){
    let params: PreLoginPeticion = new PreLoginPeticion();
    params.codCliente = environment.codCliente;
    params.urlPortal = environment.urlPortal;

    this.autenticatorService.preLogin(params).subscribe(data => {
      if (data.codret == '0') {
        sessionStorage.setItem('preLogin', JSON.stringify(data));
        sessionStorage.setItem('tokenSession', data.tokenSession);

        this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));

        this.configParExpress();
      }
    }, error => {
      sessionStorage.setItem('preLoginError', JSON.stringify(error));
      this.router.navigate(['/mantencion']);
    });
  }

  configParExpress() {
    this.autenticatorService.getLoginPar().subscribe(data => {
      if (data.codret == '0') {
        //sessionStorage.setItem('tokenSession', data.tokenSession);
        sessionStorage.setItem('tokenSessionParExpress', data['tokenSession']);

        this.postSentRubrosServices();
      } else {
        console.error('Error en  getLoginParExpress', data.descret);
      }
    }, err => {
      console.error('Error en  getLoginParExpress', err);
    });
  }

  redirigirUsuario(){
    if(this.urlSalida !== ''){
      window.location.href = this.urlSalida;
    }else {
        this.router.navigate(['/inicio']);
    }
  }

  optionFinRecurrencia(option: any){
    if(option.value === 0){
      this.firstFormGroup.get("cantidadDeCobros").clearValidators();
    }else if(option.value === 1){
      const fechaInicio: Date = this.convertirFecha(this.fechaValidezDesde);
      let fechaFutura = new Date();
      if(fechaInicio > fechaFutura){
        fechaFutura = fechaInicio;
      }
      fechaFutura.setFullYear(fechaFutura.getFullYear() + 1);

      this.fechaValidezFin = this.formatFecha(fechaFutura);
      this.firstFormGroup.get("fechaFinVigencia").setValue(fechaFutura);      
      this.firstFormGroup.get("cantidadDeCobros").clearValidators();
      this.unavailabilityForm.get("minDateFin").setValue(this.convertirFecha(this.fechaValidezDesde));
    }else if(option.value === 2){
      this.firstFormGroup.get("cantidadDeCobros").setValidators(Validators.compose([Validators.required, CustomValidators.numberValidator, Validators.min(1), Validators.max(1000000)]));
    }

    this.firstFormGroup.get("cantidadDeCobros").updateValueAndValidity();

    this.radioButtonOption = option.value;
  }

  selectFechaValidez(event: SatDatepickerInput<Date>) {
    this.fechaValidezDesde = formatDate(event.value.toString(), 'dd/MM/yyyy', 'en-US');

    this.unavailabilityForm.get("minDateFin").setValue(this.convertirFecha(this.fechaValidezDesde));

    if(this.radioButtonOption === 1){
      const fechaInicio: Date = this.convertirFecha(this.fechaValidezDesde);
      const fechaFin: Date = this.convertirFecha(this.fechaValidezFin);

      if(isDate(fechaInicio) && isDate(fechaFin)){
        if(fechaInicio > fechaFin){ //fechaInicio es mayor
          let fechaFutura = fechaInicio;
          fechaFutura.setFullYear(fechaFutura.getFullYear() + 1);
          this.fechaValidezFin = this.formatFecha(fechaFutura);
          this.showDialog('Información:', `La fecha de inicio no puede ser mayor <br> a la fecha de fin de recurrencia. <br> Se actualiz&oacute; la fecha de fin de recurrencia autom&aacute;ticamente.` , false, 'Cancelar', 'Aceptar' );
          this.firstFormGroup.get("fechaFinVigencia").setValue(fechaFutura);
        }
      }
    }else{
      this.firstFormGroup.get('fechaFinVigencia').clearValidators();
    }
    this.firstFormGroup.get('fechaFinVigencia').updateValueAndValidity();
  }

  selectFechaValidezFin(event: SatDatepickerInput<Date>) {
    this.fechaValidezFin = formatDate(event.value.toString(), 'dd/MM/yyyy', 'en-US');
    this.firstFormGroup.get("fechaFinVigencia").setValue(this.convertirFecha(this.fechaValidezFin));
  }

  formatFecha(fecha: Date){
    const day = fecha.getDate().toString().padStart(2, '0');
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const year = fecha.getFullYear();

    return `${day}/${month}/${year}`;
  }

  convertirFecha(fechaStr: string): Date | null {
    const partes = fechaStr.split('/');
    if(partes.length === 3){
      const day = parseInt(partes[0], 10);
      const month = parseInt(partes[1], 10) - 1;
      const year = parseInt(partes[2], 10);
      return new Date(year, month, day);
    }
    return null;
  }

  chequearMoneda(codUnidMonOption: string): boolean {
    const isCLP = codUnidMonOption === 'CLP';
    const isEmptySelection = !this.unidadMonetariaSelec || this.unidadMonetariaSelec.trim() === '';
    return codUnidMonOption === this.unidadMonetariaSelec || (isCLP && isEmptySelection);
  }

  onCodUnidMonetariaSelect(event: any) {
    console.log('onCodUnidMonetariaSelect '+ event.value);
    
    this.firstFormGroup.get('medioPagoMontoMax').setValue('0');
    this.firstFormGroup.get('montofijo').setValue('');

    this.unidadMonetariaSelec = event.value;

    this.expCharByMoneda = this.unidadMonetariaSelec === 'CLP' ? '^[0-9]*$' : '^\d{1,3}(?:\.\d{3})*(?:,\d{1,2})?$';

    this.lengthMontoPorMoneda  = this.unidadMonetariaSelec === 'UF' || this.unidadMonetariaSelec === 'UTM' ? 13 : 11;
    this.firstFormGroup.get('montofijo').setValidators(Validators.compose([Validators.required, Validators.maxLength(this.lengthMontoPorMoneda), CustomValidators.amountValidator, CustomValidators.greaterThanZero]));
    this.firstFormGroup.get('medioPagoMontoMax').setValidators(Validators.compose([Validators.maxLength(this.lengthMontoPorMoneda), CustomValidators.amountValidator]));
  }

  getMtoAPagar(tipoCobro: string){
    try {        
        let mtoAPagar = '0';

        if(tipoCobro === 'V'){
          if(this.firstFormGroup && this.firstFormGroup.get('medioPagoMontoMax')){
            mtoAPagar = this.firstFormGroup.get('medioPagoMontoMax').value;
          }
        }

        if(tipoCobro === 'F'){
          if(this.firstFormGroup && this.firstFormGroup.get('montofijo')){
            mtoAPagar = this.firstFormGroup.get('montofijo').value;
          }
        }

        const prefix = (this.unidadMonetariaSelec == 'UF' || this.unidadMonetariaSelec == 'UTM') ? this.unidadMonetariaSelec : '$';
        const value = (mtoAPagar !== '' && mtoAPagar !== '0,0'? this.formatCurrencyChile.transform(mtoAPagar, this.unidadMonetariaSelec) : '0');
        return prefix + " " + value;
    } catch (error) {
      console.log("Error getMtoAPagar", error) 
    }
    return '$ 0';
  }

  actualizarValorMtoMax(nuevoValor: string){
    if(!(nuevoValor === "0" || nuevoValor === "")){
      this.firstFormGroup.get('medioPagoMontoMax').setValue(this.formatCurrencyChile.transform(nuevoValor, this.unidadMonetariaSelec));
    }
  }

  actualizarValorMtoFijo(nuevoValor: string){
    if(!(nuevoValor === "0" || nuevoValor === "")){
      this.firstFormGroup.get('montofijo').setValue(this.formatCurrencyChile.transform(nuevoValor, this.unidadMonetariaSelec));
    }
  }

  initializeCountDown(){
    //Expiracion de tiempo
    //Se configura el contador de expiracion de token
    let minExpSession: number = 9 * 60;
    this.countdownConfig.leftTime = minExpSession;
    this.countdownConfig.notify = [30];
    this.counter.config = this.countdownConfig;

    //Se configura el contador de expiracion de inactividad
    let minExpInactivity: number = 9 * 60;
    this.countdownInactivityConfig.leftTime = minExpInactivity;
    this.countdownInactivityConfig.notify = [30];
    this.counterIncativity.config = this.countdownInactivityConfig;
  }

}
