import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ModuloMandatosWebModule } from 'modulo-mandatos-web';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CountdownModule } from 'ngx-countdown';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AppComponent } from './app.component';
import { CambioPassComponent } from './components/autenticador/cambio-pass/cambio-pass.component';
import { InicioHabienteComponent } from './components/autenticador/inicio-habiente/inicio-habiente.component';
import { LoginComponent } from './components/autenticador/login/login.component';
import { MantencionComponent } from './components/autenticador/mantencion/mantencion.component';
import { RecuperarPassCambioClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-cambio-clave/recuperar-pass-cambio-clave.component';
import { RecuperarPassClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-clave/recuperar-pass-clave.component';
import { RecuperarPassCorreoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-correo/recuperar-pass-correo.component';
import { RecuperarPassExitoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-exito/recuperar-pass-exito.component';
import { Interceptor } from './components/autenticador/session-validation/session-interceptor';
import { CrearCuentaClaveComponent } from './components/crear-cuenta/crear-cuenta-clave/crear-cuenta-clave.component';
import { CrearCuentaCorreoComponent } from './components/crear-cuenta/crear-cuenta-correo/crear-cuenta-correo.component';
import { CrearCuentaDatosComponent } from './components/crear-cuenta/crear-cuenta-datos/crear-cuenta-datos.component';
import { CrearCuentaExitoComponent } from './components/crear-cuenta/crear-cuenta-exito/crear-cuenta-exito.component';
import { DialogBaseComponent } from './components/dialogs/dialog-base/dialog-base.component';
import { LoadingBaseComponent } from './components/dialogs/loading-base/loading-base.component';
import { EditarCuentaComponent } from './components/editar-cuenta/editar-cuenta.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { AutenticadorService } from './services/autenticador/autenticador.service';
import { CrearCuentaService } from './services/crearCuenta/crear-cuenta.service';
import { ValidaCrearCuentaClaveService } from './services/crearCuenta/validaCrearCuentaClave.service';
import { ValidaCrearCuentaDatosService } from './services/crearCuenta/validaCrearCuentaDatos.service';
import { ValidaCrearCuentaExitoService } from './services/crearCuenta/validaCrearCuentaExito.service';
import { MantencionService } from './services/mantencion.service';
import { ValidaRecuperarPassCambioClaveService } from './services/recuperarPass/validaRecuperarPassCambioClave.service';
import { ValidaRecuperarPassClaveService } from './services/recuperarPass/validaRecuperarPassClave.service';
import { ValidaRecuperarPassCorreoService } from './services/recuperarPass/validaRecuperarPassCorreo.service';
import { ValidaRecuperarPassExitoService } from './services/recuperarPass/validaRecuperarPassExito.service';
import { SecurityService } from './services/security.service';
import { ValidaCambioPassService } from './services/validaCambioPass.service';
import { ValidaInicioService } from './services/validaInicio.service';
import { ValidaMenuService } from './services/validaMenu.service';
import { ValidaModuloService } from './services/validaModulo.service';
import { NumberDirective } from './util/numbers-only.directive';
import { CrearCuentaValidaSmsComponent } from './components/crear-cuenta/crear-cuenta-valida-sms/crear-cuenta-valida-sms.component';
import { ValidaCrearCuentaSmsService } from './services/crearCuenta/validaCrearCuentaSms.service';
import { ValidaSmsService } from './services/validaSms.service';
import { EditarCuentaValidaSmsComponent } from './components/editar-cuenta/editar-cuenta-valida-sms/editar-cuenta-valida-sms.component';
import { RecuperarPassValidaSmsComponent } from './components/autenticador/recuperar-pass/recuperar-pass-valida-sms/recuperar-pass-valida-sms.component';
import { ValidaRecuperarPassValidaSmsService } from './services/recuperarPass/validaRecuperarPassValidaSms.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogPoliticaPrivacidadComponent } from './components/dialogs/dialog-politica-privacidad/dialog-politica-privacidad.component';
import { AddMandatosExpressComponent } from './components/add-mandatos-express/add-mandatos-express.component';
import { CondicionesGeneralesComponent } from './components/condiciones-generales/condiciones-generales.component';
import { MaskCardNumberPipe } from './util/mask-card-number.pipe';
import { FormatRutDirective, SoloRutDirective } from './util/solo-rut.directive';
import { CantidadStr, MonedaStr, NumberStr, MilesSeparador, NumberMonedasStr, FormatMonedaChilePipe } from './util/currency.pipe';
import { ValidaManExpressService } from './services/validaManExpress.service';
import { CondicionesGeneralesService } from './services/condiciones-generales/condiciones-generales.service';
import { CharacterAllowedDirective } from './util/digitAndComma.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    CambioPassComponent,
    MantencionComponent,
    InicioHabienteComponent,
    CrearCuentaCorreoComponent,
    NumberDirective,
    CrearCuentaClaveComponent,
    CrearCuentaValidaSmsComponent,
    CrearCuentaDatosComponent,
    CrearCuentaExitoComponent,
    RecuperarPassCorreoComponent,
    RecuperarPassClaveComponent,
    RecuperarPassCambioClaveComponent,
    RecuperarPassExitoComponent,
    DialogBaseComponent,
    LoadingBaseComponent,
    EditarCuentaComponent,
    CrearCuentaValidaSmsComponent,
    EditarCuentaValidaSmsComponent,
    RecuperarPassValidaSmsComponent,
    DialogPoliticaPrivacidadComponent,
    AddMandatosExpressComponent,
    CondicionesGeneralesComponent,
    MaskCardNumberPipe,
    SoloRutDirective,
    CantidadStr,
    NumberStr,
    MonedaStr,
    FormatRutDirective,
    MilesSeparador,
    NumberMonedasStr,
    CharacterAllowedDirective,
    FormatMonedaChilePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    DigitOnlyModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    ModuloMandatosWebModule.forRoot(environment),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    CountdownModule,
    ModalModule.forRoot(),
    PdfViewerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    LoginComponent,
    HomeComponent,
    AddMandatosExpressComponent,
    CondicionesGeneralesComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule
  ],
  providers: [
    AutenticadorService,
    SecurityService,
    MantencionService,
    ValidaMenuService,
    ValidaModuloService,
    ValidaCambioPassService,
    CrearCuentaService,
    ValidaInicioService,
    ValidaManExpressService,
    ValidaCrearCuentaClaveService,
    ValidaCrearCuentaSmsService,
    ValidaCrearCuentaDatosService,
    ValidaCrearCuentaExitoService,
    ValidaRecuperarPassCorreoService,
    ValidaRecuperarPassClaveService,
    ValidaRecuperarPassValidaSmsService,
    ValidaRecuperarPassCambioClaveService,
    ValidaRecuperarPassExitoService,
    ValidaSmsService,
    CondicionesGeneralesService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [DialogBaseComponent, LoadingBaseComponent, DialogPoliticaPrivacidadComponent, CondicionesGeneralesComponent]
})
export class AppModule { }
