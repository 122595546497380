import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PoliticaPrivacidadService } from '../../../services/politica-privacidad/politica-privacidad.service';
import { DialogData } from '../dialog-base/dialog-base.component';
import { LoaderBaseService } from '../../../services/loader-base.service';



@Component({
  selector: 'app-dialog-politica-privacidad',
  templateUrl: './dialog-politica-privacidad.component.html',
  styleUrls: ['./dialog-politica-privacidad.component.css'],
  providers: [PoliticaPrivacidadService]
})
export class DialogPoliticaPrivacidadComponent implements OnInit {
  archivoPDFBase64: string;
  existDocumento: boolean = true;
  descError: string = "No existe documento de Políticas de uso y privacidad."

  constructor(
    private loader: LoaderBaseService,
    public politicaPrivacidadService: PoliticaPrivacidadService,
    public dialogRef: MatDialogRef<DialogPoliticaPrivacidadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.postMostrarPoliticasPrivacidad();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  public postMostrarPoliticasPrivacidad() {
    this.loader.show();
    this.politicaPrivacidadService.postMostrarPoliticasPrivacidad().subscribe(data => {
      if (data.codret == '0') {
        this.existDocumento = true;
        this.loader.hide();
        this.archivoPDFBase64 = `data:application/pdf;base64,${data.documento}`;
      } else {
        this.existDocumento = false;
        this.loader.hide();
      }
    },
      err => {
        this.existDocumento = false;
        this.loader.hide();
        console.log(err);
      }
    );
  }

  descargarPDF() {
    this.politicaPrivacidadService.postMostrarPoliticasPrivacidad().subscribe(data => {
      if (data.codret == '0') {
        const source = `data:application/pdf;base64,${data.documento}`;
        const link = document.createElement("a");
        link.href = source;
        link.download = 'Politicas_uso_privacidad_' + formatDate(new Date(), 'yyyy/MM/dd', 'en') + '.pdf';
        link.click();
      }
    },
      err => {
        console.log(err);
      }
    );
  }

}
