import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

export class TerminoCondicionesFinal {
    public idMandato : string = '';
    public idUsuario : string = '';
    public codTpoPortal : string = '';
    public empresa : string = '';
}