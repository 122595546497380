import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InscribirMandatosModel } from '../models/mandatos-express/addMandato';
import { RubroRequest } from '../models/mandatos-express/rubro-request';
import { EmpresasRubrosRespuesta } from '../models/mandatos-express/empresasRubrosRespuesta';
import { EmpresasRespuesta } from '../models/mandatos-express/empresasRespuesta';
import { ListPacParametroPeticion } from '../models/mandatos-express/listPacParametroPeticion';
import { CiudadesRespuesta } from '../models/mandatos-express/ciudadesRespuesta';
import { Ciudad } from '../models/mandatos-express/ciudad';
import { BuscarImagenBoleta } from '../models/mandatos-express/buscarImagenBoleta';
import { BuscarImagenRespuesta } from '../models/mandatos-express/buscarImagenRespuesta';
import { Embebido } from '../models/mandatos-express/apiEmbebidoPeticion';
import { EmbebidoResponse } from '../models/mandatos-express/apiEmbebidoResponse';
import { ListPeticionEmbebido } from '../models/mandatos-express/listPeticionEmbebido';
import { ListRespuestaEmbebido } from 'modulo-mandatos-web/lib/models/listRespuestaEmbebido';
import { EmpresasPeticion } from '../models/mandatos-express/empresasPeticion';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class MandatosExpressService {

  constructor(
    private http: HttpClient,
    @Inject('env') private environment: any
  ) { }

  urlApiMandatosExpress = this.environment.urlAmbiente + '/apiMandatos/mandatoAgregarExpress';
  urlEmpresaRubroExpress = this.environment.urlAmbiente + '/apiEmpresa/listarRubroExpress';
  urlEmpresaExpress = this.environment.urlAmbiente + '/apiEmpresa/listarEmpresaExpress';
  urlPacParametroListarExpress = this.environment.urlAmbiente + '/apiConvenio/pacParametroListarExpress';
  urlImagenBolExpress = this.environment.urlAmbiente + '/apiConvenio/seekImageExpress';
  urlApiData = this.environment.urlAmbiente + '/apiDatosPAT/consultausuario';

  urlPacParametroListar = this.environment.urlAmbiente + '/apiConvenio/pacParametroListar';
  urlEmpresa = this.environment.urlAmbiente + '/apiEmpresa/listarEmpresaHabiente';
  urlBuscarImagenBoleta = this.environment.urlAmbiente + '/apiConvenio/seekImage';
  urlEmpresaRubro = this.environment.urlAmbiente + '/apiEmpresa/listarRubro';
  urlApiMandatos = this.environment.urlAmbiente + '/apiMandatos/mandatoAgregar';


  postAddMandato(model: InscribirMandatosModel, isEmbebido: boolean) {
    let url = isEmbebido ? this.urlApiMandatos : this.urlApiMandatosExpress;
    return this.http.post<any>(url, model, httpOptions);
  }

  postListaRubros(params: RubroRequest, isEmbebido: boolean): Observable<EmpresasRubrosRespuesta> {
    let url = isEmbebido ? this.urlEmpresaRubro : this.urlEmpresaRubroExpress;
    return this.http.post<EmpresasRubrosRespuesta>(url, params, httpOptions);
  }

  postListaEmpresas(body: EmpresasPeticion, isEmbebido: boolean ): Observable<EmpresasRespuesta> {
    if(isEmbebido){
      return this.http.post<EmpresasRespuesta>(this.urlEmpresa, body, httpOptions);
    }    
    return this.http.post<EmpresasRespuesta>(this.urlEmpresaExpress, body, httpOptions);
  }

  postPacParametroListar(body: ListPacParametroPeticion, isEmbebido: boolean): Observable<any> {
    let url = isEmbebido ? this.urlPacParametroListar : this.urlPacParametroListarExpress;
    return this.http.post<any>(url, body, httpOptions);
  }

  listCiudadesReport(): Observable<CiudadesRespuesta> {
    //TODO Se debe actualizar con servicio real...
    let respuesta: CiudadesRespuesta = new CiudadesRespuesta();
    let ciudades: Array<Ciudad> = [];
    ciudades.push({ codigo: '1', valor: 'Aisén del G. Carlos Ibáñez del Campo' });
    ciudades.push({ codigo: '2', valor: 'Antofagasta' });
    ciudades.push({ codigo: '3', valor: 'Arica y Parinacota' });
    ciudades.push({ codigo: '4', valor: 'Atacama' });
    ciudades.push({ codigo: '5', valor: 'Biobío' });
    ciudades.push({ codigo: '6', valor: 'Coquimbo' });
    ciudades.push({ codigo: '7', valor: 'La Araucanía' });
    ciudades.push({ codigo: '8', valor: 'Libertador General Bernardo O’Higgins' });
    ciudades.push({ codigo: '9', valor: 'Los Lagos' });
    ciudades.push({ codigo: '10', valor: 'Los Ríos' });
    ciudades.push({ codigo: '11', valor: 'Magallanes y de la Antártica Chilena' });
    ciudades.push({ codigo: '12', valor: 'Maule' });
    ciudades.push({ codigo: '13', valor: 'Metropolitana de Santiago' });
    ciudades.push({ codigo: '14', valor: 'Ñuble' });
    ciudades.push({ codigo: '15', valor: 'Tarapacá' });
    ciudades.push({ codigo: '16', valor: 'Valparaíso' });

    respuesta.resultado = ciudades;
    respuesta.codret = '0';
    respuesta.descret = 'Proceso exitoso';
    return of(respuesta);
  }

  postDatosEmbebido(body: ListPeticionEmbebido): Observable<ListRespuestaEmbebido>{
    return this.http.post<ListRespuestaEmbebido>(this.urlApiData, body, httpOptions);
  }

  postBuscarImagenBoleta(imagen: BuscarImagenBoleta, isEmbebido: boolean): Observable<BuscarImagenRespuesta> {
    let url = isEmbebido ? this.urlBuscarImagenBoleta : this.urlImagenBolExpress;
    return this.http.post<BuscarImagenRespuesta>(url, imagen, httpOptions);
  }
}
