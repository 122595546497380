import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CambioPassPeticion } from 'src/app/models/cambio-pass/cambioPass-peticion';
import { PoliticaPeticion } from 'src/app/models/politica/politica-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { DialogBaseComponent } from '../../dialogs/dialog-base/dialog-base.component';
import { HomeComponent } from '../../home/home.component';


@Component({
  selector: 'app-cambio-pass',
  templateUrl: './cambio-pass.component.html',
  styleUrls: ['./cambio-pass.component.css'],
  providers: [AutenticadorService, HomeComponent]
})
export class CambioPassComponent implements OnInit {
  hideActual = true;
  hideNueva = true;
  hideRepita = true;
  loading = false;
  userPerfil;
  errorCambioPass: boolean;
  disableButtom = false;
  errorMensage: string;
  tienePolitica: boolean;
  lrgMinPwd: string;
  lrgMaxPwd: string;
  canLetrasMayPwd: string;
  canLetrasMinPwd: string;
  canNumerosMinPwd: string;
  canCaracteresMinPwd: string;
  textoPolitica: string;
  preLoginsLS: { codTpoPortal: string; };

  FormGroup: FormGroup;
  cambioPassPeticion = new CambioPassPeticion();
  politicaPeticion = new PoliticaPeticion();

  infoCambioPass = false;
  infoMensage: string;

  constructor(
    private router: Router,
    private autenticatorService: AutenticadorService,
    private home: HomeComponent,
    public dialog: MatDialog,
    @Inject('env') private environment: any,
  ) {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
  }

  doPolitica(body: PoliticaPeticion) {
    this.autenticatorService.doPolitica(body).subscribe(data => {
      if (data.codret == "0") {
        this.tienePolitica = true;
        if (data.lrgMinPwd == '0' && data.lrgMaxPwd == '0' && data.canLetrasMayPwd == '0' && data.canLetrasMinPwd == '0' && data.canNumerosMinPwd == '0' && data.canCaracteresMinPwd == '0') {
          this.textoPolitica = "No existen restricciones para crear una clave";
        } else {
          this.textoPolitica = "Políticas de restricciones para crear una clave:";
          if (data.lrgMinPwd != '0') {
            this.lrgMinPwd = "Longitud mínima: " + data.lrgMinPwd;
          }
          if (data.lrgMaxPwd != '0') {
            this.lrgMaxPwd = "Longitud máxima: " + data.lrgMaxPwd;
          }
          if (data.canLetrasMayPwd != '0') {
            this.canLetrasMayPwd = "Cantidad letras mayúsculas: " + data.canLetrasMayPwd;
          }
          if (data.canLetrasMinPwd != '0') {
            this.canLetrasMinPwd = "Cantidad letras minúsculas: " + data.canLetrasMinPwd;
          }
          if (data.canNumerosMinPwd != '0') {
            this.canNumerosMinPwd = "Cantidad mínima de números: " + data.canNumerosMinPwd;
          }
          if (data.canCaracteresMinPwd != '0') {
            this.canCaracteresMinPwd = "Cantidad mínima caracteres especiales: " + data.canCaracteresMinPwd;
          }
        }
      } else {
        this.errorCambioPass = true;
        this.errorMensage = "" + data.descret;
        this.loading = false;
        this.disableButtom = true;
      }

    }, error => {
      this.errorCambioPass = true;
      this.disableButtom = true;
      this.errorMensage = "Código de error: " + "(" + error.status + ") " + "Política de restricción de clave no encontrada.";
      this.loading = false;
    });
  }
  ngOnInit() {
    //console.log('cambioPass');
    this.FormGroup = new FormGroup({
      usuario: new FormControl('', Validators.required),
      passwordActual: new FormControl('', Validators.required),
      nuevaPassword: new FormControl('', Validators.required),
      repitaPassword: new FormControl('', Validators.required)
    });
    this.FormGroup.controls['usuario'].setValue(sessionStorage.getItem('docUsuario'));
    this.FormGroup.controls['usuario'].disable();
    if (sessionStorage.getItem('infoCambioPass')) {
      this.infoCambioPass = true;
      this.infoMensage = sessionStorage.getItem('infoCambioPass');
    }

    this.politicaPeticion.codCliente = this.environment.codCliente;
    this.politicaPeticion.idPerfil = sessionStorage.getItem('idPerfil');
    this.doPolitica(this.politicaPeticion);
  }
  cancelarCambiarPass() {
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    if (this.userPerfil.usuario.codEstado == 'CAMBIOPASS') {
      this.router.navigate(['/login']);
    } else {
      sessionStorage.removeItem('urlSelected');
      this.router.navigate(['/home']);
    }
  }
  doCambioPass(body: CambioPassPeticion) {
    this.autenticatorService.doCambioPass(body).subscribe(data => {
      if (data.codret == "0") {
        this.showDialog('Información', 'Clave modificada de forma satisfactoria.<br>Por seguridad la sesión se cerrará', false);
      } else {
        this.showDialogFail('Error', 'No ha sido posible modificar su clave.<br>' + data.descret, false);
        this.loading = false;
      }

    }, error => {
      this.showDialogFail('Error', 'Servicio temporalmente no disponible.', false);
      this.loading = false;
    });
  }

  cambiarPass(content: any) {
    this.loading = true;
    if (this.FormGroup.controls['nuevaPassword'].value == this.FormGroup.controls['repitaPassword'].value) {
      //this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));

      this.cambioPassPeticion.codCliente = this.environment.codCliente;
      this.cambioPassPeticion.docEmpresa = this.environment.docEmpresa;
      this.cambioPassPeticion.docUsuario = this.FormGroup.controls['usuario'].value;
      this.cambioPassPeticion.claveActual = this.FormGroup.controls['passwordActual'].value;
      this.cambioPassPeticion.claveNueva = this.FormGroup.controls['nuevaPassword'].value;
      this.cambioPassPeticion.claveRepetida = this.FormGroup.controls['repitaPassword'].value;
      this.cambioPassPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

      this.cambioPassPeticion.tipoCambio = 'C';
      this.showDialogPre('Información', 'Si realiza esta acción, modificará su clave actual.<br>¿Está seguro?', true);
    } else {
      this.showDialogFail('Error', 'Nueva contraseña no coincide con repetir contraseña.', false);
      this.loading = false;
    }
  }

  goLogin() {
    this.home.logout();
  }

  showDialogPre(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed ' + result);
      if(result == true){
        this.doCambioPass(this.cambioPassPeticion);
      }else{
        this.loading=false;
      }
    });
  }
  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ' + result);
      this.goLogin();
    });
  }
  showDialogFail(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
