export class InscribirMandatosModel {
        public idRecaudador: string;
        public rutSuscriptor: string;
        public nombreSuscriptor: string;
        public apellido1Suscriptor: string;
        public apellido2Suscriptor: string;
        public idServicioEmpresa: string;
        public idEmpresa: string;
        public idFacturador: string;
        public idServicio: string;
        public servicioAlias: string;
        public medioPago: string;
        public tipoMedioPago: string;
        public medioPagoMontoMax: string;
        public montofijo: string;
        public mesMedioPago: string;
        public anioMedioPago: string;
        public codSeg: string;
        public contactoEmail: string;
        public contactoFijo: string;
        public contactoMovil: string;
        public contactoDireccion: string;
        public contactoCiudad: string;
        public contactoNotificacion: string;
        public canal: string;
        public idMandante: string;
        public docEmpresa: string;
        public codCliente: string;
        public codTpoPortal: string;
        public diaPago: string;
        public enviaMail: string;
        public nombrePromotor: string;
        public tipoRecurrencia: number;
        public fechaInicioRecurrencia: string;
        public fechaFinRecurrencia: string;
        public cantidadRecurrencia: number;
        public codUnidadMonetaria: string;
}