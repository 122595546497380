import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ValidaInicioService implements CanActivate {

  preLogin:any = JSON.parse(sessionStorage.getItem('preLogin'));

  constructor(private router: Router) { }

  canActivate() {
    if (this.preLogin.codTpoPortal == 'HABIENTE' && this.preLogin.estadoPortal != 'SUSPENDIDO') {
      return true;
    } else {
      this.router.navigate(['inicio']);
      return false;
    }
  }

}