import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { DialogBaseComponent } from 'src/app/components/dialogs/dialog-base/dialog-base.component';
import { RecuperarPassClavePeticion } from 'src/app/models/recuperar-pass/recuperarPassClave-peticion';
import { RecuperarPassCorreoPeticion } from 'src/app/models/recuperar-pass/recuperarPassCorreo-peticion';
import { RecuperarPassService } from 'src/app/services/recuperarPass/recuperar-pass.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-recuperar-pass-clave',
  templateUrl: './recuperar-pass-clave.component.html',
  styleUrls: ['./recuperar-pass-clave.component.css'],
  providers: [RecuperarPassService]
})
export class RecuperarPassClaveComponent implements OnInit {
  hideNueva = true;
  FormGroup: FormGroup;
  loading: boolean = false;
  loadingReenvio: boolean = false;
  errorValidaClave: boolean;
  errorMensage: string;
  correoRecuperarPass: string;
  movilValido: string;
  recuperarPassClavePeticion = new RecuperarPassClavePeticion();
  recuperarPassCorreoPeticion = new RecuperarPassCorreoPeticion();
  preLoginsLS: { codTpoPortal: string; };
  reenviar: boolean = true;
  movilUltimosDigitos: string = "";

  constructor(public dialog: MatDialog, private router: Router, private recuperarPassService: RecuperarPassService) {
    this.preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    this.correoRecuperarPass = sessionStorage.getItem("correoRecuperarPass");
    this.movilValido = sessionStorage.getItem("movilValido");
  }

  @ViewChild('countdown', null) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  ngOnInit() {
    sessionStorage.removeItem('conexionSmsFailed');
    this.FormGroup = new FormGroup({
      clave: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]))
    });

    if (sessionStorage.getItem('reenviar') != null) {
      this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
    }

    if (sessionStorage.getItem('countDown') != null) {
      this.countdownConfig.leftTime = JSON.parse(sessionStorage.getItem('countDown')) / 1000;
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    } else {
      this.countdownConfig.leftTime = 300; // 5 minutos - 300
      this.countdownConfig.notify = 0;
      this.counter.config = this.countdownConfig;
    }
  }
/* Valida codigo temporal */
  recuperarPassClave() {
    this.loading = true;
    //this.recuperarPassClavePeticion.codCliente = environment.codCliente;
    this.recuperarPassClavePeticion.docEmpresa = environment.docEmpresa;
    //this.recuperarPassClavePeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.recuperarPassClavePeticion.claveUsuario = this.FormGroup.get('clave').value;
    this.recuperarPassClavePeticion.correoUsuario = this.correoRecuperarPass;
    //this.recuperarPassClavePeticion.movilUsuario = this.movilValido.substring(2);
    //this.recuperarPassClavePeticion.countryMovilUsuario = '+' + this.movilValido.substring(0,2);
    this.recuperarPassClavePeticion.movilUsuario = "";
    this.recuperarPassClavePeticion.countryMovilUsuario = "";
    this.recuperarPassClavePeticion.tipoEnvio = 'RECUPERARCLAVE';
    this.doRecuperarClave(this.recuperarPassClavePeticion);
  }

  doRecuperarClave(body: RecuperarPassClavePeticion) {
    this.recuperarPassService.doRecuperarClave(body).subscribe(data => {
      if (data.codret == '0' || data.codret == '-90') {
        if(data.codret == '-90'){
          sessionStorage.setItem('conexionSmsFailed', JSON.stringify(data.descret));
        }
        this.movilUltimosDigitos = data.movilUsuario;
        sessionStorage.removeItem('countDown');
        sessionStorage.removeItem('reenviar');
        sessionStorage.setItem('recuperarPassClaveV', JSON.stringify(data));
        sessionStorage.setItem('recuperarPassClaveActual', this.FormGroup.get('clave').value);
        this.router.navigate(['/recuperarpassvalidasms']);
        //this.router.navigate(['/recuperarpasscambioclave']);
      } else {
        this.loading = false;
        if (data.codret == '-4') {
          this.showDialog('Error', data.descret, false);
        }
        this.errorValidaClave = true;
        if(data.codret != '-4'){
          this.errorMensage = data.descret;
        }
      }
    }, error => {
      this.errorValidaClave = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loading = false;
    });
  }

  /* Reenvia codigo temporal por correo */
  reenviarCorreo() {
    sessionStorage.setItem('reenviar', 'true');
    this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));

    this.doReenviarCorreo();
  }
  doReenviarCorreo() {
    this.loadingReenvio = true;
    this.recuperarPassCorreoPeticion.correoUsuario = this.correoRecuperarPass;
    //this.recuperarPassCorreoPeticion.codCliente = environment.codCliente;
    this.recuperarPassCorreoPeticion.docEmpresa = environment.docEmpresa;
    //this.recuperarPassCorreoPeticion.codTpoPortal = this.preLoginsLS.codTpoPortal;

    this.recuperarPassService.doRecuperarCorreo(this.recuperarPassCorreoPeticion).subscribe(data => {
      if (data.codret == '0') {
        //sessionStorage.setItem('movilValido', data.movilUsuario);
        //this.movilValido = data.movilUsuario;
        this.loadingReenvio = false
      } else {
        this.errorValidaClave = true;
        this.errorMensage = data.descret;
        this.loadingReenvio = false;
      }
    }, error => {
      this.errorValidaClave = true;
      this.errorMensage = 'Servicio temporalmente no disponible.';
      this.loadingReenvio = false;
    });
  }

  onTimerFinished(e: Event) {
    if (e["action"] == "done") {
      this.showDialog('Límite de tiempo', 'Ha excedido el tiempo de validación de clave por correo.', false);
    } else {
      sessionStorage.setItem('countDown', e['left']);
      if(e['text'] == '00:04:50'){
        sessionStorage.setItem('reenviar', 'false');
        this.reenviar = JSON.parse(sessionStorage.getItem('reenviar'));
      }
    }
  }

  showDialog(pTitle: string, pContent: string, pShowBtnCancel: boolean) {
    const dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: pTitle, content: pContent, showBtnCancel: pShowBtnCancel, btnCancelar: 'Cancelar', btnAceptar: 'Aceptar' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/inicio']);
      }
    });
  }
}
